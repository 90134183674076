import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-new-input",
  templateUrl: "./new-input.component.html",
  styleUrls: ["./new-input.component.scss"],
})
export class NewInputComponent {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() formControlProps: FormControl;
  @Input() disabledProps: boolean = false;
  @Input() withMaxProps: number = 0;
  @Input() scanBarcodeProps: boolean = false;
  @Input() inputTypeProps: string = "text";

  constructor() {}

  public selectMax(): void {
    this.formControlProps.setValue(this.withMaxProps);
  }
}

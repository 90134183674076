import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { IShortEntity } from "../../interfaces/shortEntity.interface";

@Component({
  selector: "app-new-select",
  templateUrl: "./new-select.component.html",
  styleUrls: ["./new-select.component.scss"],
})
export class NewSelectComponent implements OnInit, OnDestroy {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() optionsProps: IShortEntity[] = [];
  @Input() disabledProps: boolean = false;
  @Input() formControlProps: FormControl;

  @Output() optionSelected = new EventEmitter<IShortEntity>();

  public isActive: boolean = false;
  public selectedOption: IShortEntity = null;

  private globalClickUnlistener: () => void;
  private subscriptions: Subscription = new Subscription();

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this.selectedOption = this.formControlProps?.value || null;

    if (this.formControlProps) {
      const controlSub = this.formControlProps.valueChanges.subscribe(
        (value) => {
          this.selectedOption = value;
        }
      );
      this.subscriptions.add(controlSub);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.detachGlobalClickListener();
  }

  toggleDropdown(): void {
    if (!this.disabledProps) {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.attachGlobalClickListener();
      } else {
        this.detachGlobalClickListener();
      }
    }
  }

  selectOption(option: IShortEntity): void {
    this.selectedOption = option;
    this.formControlProps?.setValue(option);
    this.optionSelected.emit(option);
    this.isActive = false;
    this.detachGlobalClickListener();
  }

  private attachGlobalClickListener(): void {
    this.globalClickUnlistener = this._renderer.listen(
      "document",
      "click",
      (event: MouseEvent) => this.onGlobalClick(event)
    );
  }

  private detachGlobalClickListener(): void {
    if (this.globalClickUnlistener) {
      this.globalClickUnlistener();
      this.globalClickUnlistener = null;
    }
  }

  private onGlobalClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (!this._elementRef.nativeElement.contains(target)) {
      this.isActive = false;
      this.detachGlobalClickListener();
    }
  }
}

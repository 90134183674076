import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalletHideNoTransactionsPipe } from './wallet-hide-no-transactions.pipe';
import { WalletHideZeroPipe } from './wallet-hide-zero.pipe';
import { WalletSearchPipe } from './wallet-search.pipe';
import { NumberPlacePipe } from './number-place.pipe';

@NgModule({
  declarations: [WalletHideNoTransactionsPipe, WalletHideZeroPipe, WalletSearchPipe, NumberPlacePipe],
  imports: [CommonModule],
  exports: [WalletHideNoTransactionsPipe, WalletHideZeroPipe, WalletSearchPipe, NumberPlacePipe],
})
export class WalletPipesModule {}

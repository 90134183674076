import { Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { SvgService } from '../../../services/svg.service';
import { AuthService } from '../../../services/auth.service';
import { DataService } from '../../../services/data.service';
import { ValidationService } from '../../../services/validation.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { ThemesModel } from '../../../models/themes.model';
import {BalanceInterface, BalanceModel} from '../../../models/balance.model';
import {CurrencyLastPriceClass} from '../../../models/currency-last-price.class';
import {OrderStopLimitClass} from '../../../models/order-stop-limit.class';
import {logger} from 'codelyzer/util/logger';
import {CurrencyInterface} from '../../../models/currency.model';
import {MarketsShortInfoInterface} from '../../../models/markets-short-info.model';
import {OrderStopLimitStatusClass} from '../../../models/order-stop-limit-status.class';
import { SubscribeToUpdateTradesDataInterface } from 'src/app/models/subscribe-to-update-trades-data.model';
import {ISelectOption} from '../../../ui/chips-selector/chips-selector.component';
import {ProfileState} from '../../../store/profile.state';
import {fromMobx} from '../../../store/rx-from-mobx';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public direction: 'buy' | 'sell' = 'buy';
  public currencyMain;
  public currencyAdditional;
  public balancesBlockData = [];
  public sortParams = {
    sortByToken: 'down',
    sortByBalance: ''
  };
  public pair = '';
  public balanceCurrencyMain: BalanceInterface;
  public balanceCurrencyAdditional: BalanceInterface;
  public quantityDefault = '0';
  public quantityBuy: string;
  public quantitySell: string;
  // public marketPairMinOrderAmount: MarketsShortInfoInterface;
  public priceDefault = '0';
  public priceBuy: string;
  public priceSell: string;
  public triggerPrice: string;
  public triggerBuyPrice: string;
  public triggerSellPrice: string;
  public isPendingExchangeDefault = false;
  public isPendingExchangeBuy = false;
  public isPendingExchangeSell = false;
  public isPendingGetBalances = false;
  public isAuthorisedForStopLossTakeProfit = false;
  public selectedOrderType = 'Limit';
  public selectedDefaultOrderCondition = undefined;
  public selectedBuyOrderCondition = undefined;
  public selectedSellOrderCondition = undefined;
  public selectedDefaultOrderValidationPeriod = 'GOOD_TILL_CANCELLED';
  public selectedSellOrderValidationPeriod = 'GOOD_TILL_CANCELLED';
  public selectedBuyOrderValidationPeriod = 'GOOD_TILL_CANCELLED';
  public orderTypes = ['Limit'];
  public orderConditions = ['>=', '<='];
  public orderValidationPeriods = ['GOOD_TILL_CANCELLED', 'IMMEDIATE_OR_CANCELLED'];
  public error = {
    'default': '',
    'buy': '',
    'sell': '',
  };
  public enableExchange: boolean;
  public isVisibleActualPair = false;
  public activeList = false;
  public activeDefaultTriggerConditionList = false;
  public activeBuyTriggerConditionList = false;
  public activeSellTriggerConditionList = false;
  public activeOrderDefaultValidationPeriodList = false;
  public activeOrderBuyValidationPeriodList = false;
  public activeOrderBuyTh3UpValidationPeriodList = false;
  public activeOrderSellTh3UpValidationPeriodList = false;
  public activeOrderSellValidationPeriodList = false;
  public data = {
    amount: 3,
    entryPrice: 0,
    entryVal: 0,
    takeProfit: 0,
    stopLoss: 0
  };
  // public directions = [
  //   {id: 1, value: 'Long'},
  //   {id: 2, value: 'Short'}
  // ];
  public checkedCalculatorDirection = 1;
  public takeProfitPL = 0;
  public stopLossPL = 0;
  public takeProfit = 0;
  public stopLoss = 0;
  public blockedMarkets = [''];
  public errorMessageLimitOrder = new Subject<string>();
  public errorMessageLimitOrderData: string;
  public isComparedPrevLastPrice: boolean;
  public isInitiatedComparedPrevLastPrice = false;
  public volumeOptions: ISelectOption<number>[] = [
    {label: '25%', value: 0.25},
    {label: '50%', value: 0.5},
    {label: '75%', value: 0.75},
    {label: '100%', value: 1},
  ];
  public digitsOnly = /^\d{1,10}(\.\d{0,10})?$/;
  destroySubject$: Subject<void> = new Subject();
  ThemesModel = ThemesModel;
  сurrencyLastPrice: CurrencyLastPriceClass;
  public minOrderAmount: MarketsShortInfoInterface;
  public ordersStopLimit: Array<OrderStopLimitClass>;
  public statusesOrdersStopLimit = Array<OrderStopLimitStatusClass>(0);
  selectedButton: HTMLElement | null = null;

  @ViewChild('priceDefaultInput') priceDefaultInputElement: ElementRef;
  @ViewChild('priceBuyInput') priceBuyInputElement: ElementRef;
  @ViewChild('priceSellInput') priceSellInputElement: ElementRef;
  @ViewChild('triggerPriceInput') triggerPriceInputElement: ElementRef;
  @ViewChild('triggerBuyPriceInput') triggerBuyPriceInputElement: ElementRef;
  @ViewChild('triggerSellPriceInput') triggerSellPriceInputElement: ElementRef;


  constructor(public svgService: SvgService,
              public authService: AuthService,
              public dataService: DataService,
              public profileState: ProfileState,
              public validationService: ValidationService) {
    this.currencyMain = this.dataService.market.split('-')[1];
    this.currencyAdditional = this.dataService.market.split('-')[0];
    this.calculate();
    this.enableExchange = true;
    this.loadExchangeAmount();
    this.loadExchangePrice();
    this.pair = this.dataService.market;

    // this.enableExchange = ((this.authService.isLoggedIn && (dataService.isBootingFullApp() || dataService.isBootingTradesONprofileOFF()))
    //                         || (this.authService.isLoggedIn && dataService.isBootingSoftApp() && dataService.isBluredExchange()))
    //                       ? true
    //                         : ((!authService.isLoggedIn || (authService.isLoggedIn && dataService.isBootingSoftApp())) ? false : false);
    // this.getMarketPairMinOrderAmount();

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
        this.pair = this.dataService.market;
      });

    fromMobx(() => this.profileState.isAuthorisedForStopLossTakeProfit)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isAuthorisedForStopLossTakeProfit = data;
        if (this.isAuthorisedForStopLossTakeProfit) {
          this.prepareStopLossTakeProfitView();
        }
      });

    this.getСurrencyLastPrice();
    this.getMarketPairMinOrderAmount();
  }

  ngOnInit() {
    const defaultButton = document.querySelector('.active-button') as HTMLElement;
    this.selectedButton = defaultButton || null;
    this.dataService.refreshedStopLimitOrders
            .pipe(takeUntil(this.destroySubject$))
            .subscribe(() => {
              const emittedBalances = this.profileState.getLastUserBalance();
              if (emittedBalances?.length > 0) {
                this.formattedBalances(emittedBalances);
              } else {
                this.getServerBalances();
              }
            });
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.pair = this.dataService.market;
        this.currencyMain = this.dataService.market.split('-')[1];
        this.currencyAdditional = this.dataService.market.split('-')[0];
        this.isVisibleActualPair = true;
        this.clearErrors();
        setTimeout(() => this.isVisibleActualPair = false, 2000);
      });
    if (this.authService.isLoggedIn) {
      this.getServerBalances();
      this.subsToUpdateOrders();
      this.getBlockedMarkets();
      const userId = this.profileState.storagedUserId;

      this.dataService.refreshedStopLimitOrders
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(() => {
          this.getStopLimitOrders(userId);
        });
    }
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    // this.dataService.passIsAuthorisedForStopLossTakeProfit(false);
  }

  public setDirection(direction: 'buy' | 'sell') {
    this.direction = direction;
    if (direction === 'buy' && this.selectedOrderType === 'Ladder Limit') {
      this.selectedOrderType = 'Limit';
    }
  }
  public calculate(): void {
    Object.keys(this.data).forEach(key => {
      if (this.data[key] < 0) {
        this.data[key] = 0;
      }
    });
    this.data.entryVal = this.data.amount * this.data.entryPrice;
    this.calculateProfitPLLossPL();
    // this.takeProfitPL = (this.data.amount * this.data.takeProfit) - (this.data.amount * this.data.entryPrice);
    // this.stopLossPL = (this.data.amount * this.data.stopLoss) - (this.data.amount * this.data.entryPrice);
  }

  public calculateProfitPLLossPL() {
    switch (this.checkedCalculatorDirection) {
      case 1: // Long
        this.takeProfitPL = (this.data.amount * this.data.takeProfit) - (this.data.amount * this.data.entryPrice);
        this.stopLossPL = (this.data.amount * this.data.stopLoss) - (this.data.amount * this.data.entryPrice);
        break;
      case 2: // Short
        this.takeProfitPL = (this.data.amount * this.data.entryPrice) - (this.data.amount * this.data.takeProfit);
        this.stopLossPL = (this.data.amount * this.data.entryPrice) - (this.data.amount * this.data.stopLoss);
        break;
    }
  }

  // public getMarketPairMinOrderAmount() {
  //   this.marketPairMinOrderAmount = this.dataService.getMarketPairMinOrderAmount();
  //   this.data.amount = (this.marketPairMinOrderAmount && this.marketPairMinOrderAmount.minTradeSize)
  //                     ? this.marketPairMinOrderAmount.minTradeSize
  //                     : undefined;
  //   const quantity = '' + ((this.marketPairMinOrderAmount && this.marketPairMinOrderAmount.minTradeSize)
  //                     ? this.marketPairMinOrderAmount.minTradeSize
  //                     : undefined);
  //   this.quantityDefault = quantity;
  //   this.quantityBuy = quantity;
  //   this.quantitySell = quantity;
  //   this.dataService.getMarketPairMinOrderAmountEmitter()
  //     .pipe(takeUntil(this.destroySubject$))
  //     .subscribe((response: MarketsShortInfoInterface) => {
  //       this.marketPairMinOrderAmount = response;
  //       this.data.amount = (this.marketPairMinOrderAmount && this.marketPairMinOrderAmount.minTradeSize)
  //                         ? this.marketPairMinOrderAmount.minTradeSize
  //                         : undefined;
  //       const quantity1 = '' + ((this.marketPairMinOrderAmount && this.marketPairMinOrderAmount.minTradeSize)
  //                         ? this.marketPairMinOrderAmount.minTradeSize
  //                         : undefined);
  //       this.quantityDefault = quantity1;
  //       this.quantityBuy = quantity1;
  //       this.quantitySell = quantity1;
  //     });
  // }

  public increaseAmount(inputFrom: string, amountToIncrease: number) {
    switch (inputFrom) {
      case 'quantitySell':
        this.quantitySell = '' + amountToIncrease;
        break;
      case 'quantityBuy':
        this.quantityBuy = '' + amountToIncrease;
        break;
    }
  }
  public getServerBalances() {
      this.isPendingGetBalances = true;
      this.dataService.getBalances()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((data: Array<BalanceInterface>) => {
          console.log('BALANCES', data);
          const balances = data;
          this.balancesBlockData = data.filter(balance => balance.balance > 0);
          this.formattedBalances(balances);
          this.isPendingGetBalances = false;
        }, () => {this.isPendingGetBalances = false; });
  }

 public formattedBalances(balances) {
    this.balanceCurrencyMain = balances.filter(balance => balance.currency === this.currencyMain)[0] || new BalanceModel();
    this.balanceCurrencyAdditional = balances.filter(
      balance => balance.currency === this.currencyAdditional)[0] || new BalanceModel();
  }

  public getMarketPairMinOrderAmount() {
    this.minOrderAmount = this.dataService.getMarketPairMinOrderAmount();
    if (this.minOrderAmount && !this.minOrderAmount.minTradeSize) {
      this.minOrderAmount.minTradeSize = 0;
    }
    this.dataService.getMarketPairMinOrderAmountEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(response => {
        this.minOrderAmount = response;
        if (this.minOrderAmount && !this.minOrderAmount.minTradeSize) {
          this.minOrderAmount.minTradeSize = 0;
        }
      });
  }

  public handleErrorsForCreatingStopLimitOrder(reason: string, inputFrom: string) {
      const message = (reason === 'minTradeSize') ? ('min trade size for ' + this.pair + ' is ' + this.minOrderAmount.minTradeSize)
                                                  : '';
      const defaultMessage = 'error during creation...';
      switch (inputFrom) {
        case 'inputDefault':
          this.isPendingExchangeDefault = true;
          setTimeout(() => {
            this.error.default = message || defaultMessage;
            this.isPendingExchangeDefault = false;
          }, 200);
          break;
        case 'stopLimitBuy':
          this.isPendingExchangeBuy = true;
          setTimeout(() => {
            this.error.buy =  message || defaultMessage;
            this.isPendingExchangeBuy = false;
          }, 200);
          break;
        case 'stopLimitSell':
          this.isPendingExchangeSell = true;
          setTimeout(() => {
            this.error.sell =  message || defaultMessage;
            this.isPendingExchangeSell = false;
          }, 200);
          break;
      }
   }
  // exchange part
  public exchangeBuyDefault() {
    const inputFrom = 'inputDefault';
    if (this.selectedOrderType === 'Market' && this.quantityDefault) {
      this.makeExchangeBuyMarket(+this.quantityDefault, inputFrom);
    }
    if (this.quantityDefault
        && (+this.priceDefault > 0)
        && !this.isCurrencyBlocked(this.pair)
        && (this.isLimitSelectedOrderType()
          || (this.isStopLimitSelectedOrderType() && (+this.triggerPrice > 0) && this.selectedDefaultOrderCondition))) {
      this.error.default = '';
      this.isPendingExchangeDefault = true;
      if (this.authService.isLoggedIn) {
        if (this.isLimitSelectedOrderType()) {
          this.makeExchangeBuy(+this.quantityDefault, +this.priceDefault, inputFrom);
        }
        if (this.isAuthorisedForStopLossTakeProfit && this.isStopLimitSelectedOrderType()) {
          const order = new OrderStopLimitClass('buy',
            this.dataService.getPair(),
            +this.priceDefault,
            +this.quantityDefault,
            +(+this.quantityDefault * (+this.priceDefault)).toFixed(6),
            +this.triggerPrice,
            this.selectedDefaultOrderCondition,
            this.selectedDefaultOrderValidationPeriod,
            'Stop Limit',
            0,
            '',
            0);
          if (+this.minOrderAmount.minTradeSize > order.amount) {
            this.handleErrorsForCreatingStopLimitOrder('minTradeSize', inputFrom);
          } else {
            this.makeExchangeStopLimitBuy(order, inputFrom);
          }
        }
      } else {this.youAreNotLoggedInHandler(inputFrom); }
    }
  }
  public exchangeBuy() {
    const inputFrom = 'inputBuy';
    if (this.quantityBuy
        && (+this.priceBuy > 0)
        && !this.isCurrencyBlocked(this.pair)
        && (this.isLimitSelectedOrderType()
            || (this.isStopLimitSelectedOrderType() && (+this.triggerBuyPrice > 0) && this.selectedBuyOrderCondition))) {
      this.error.buy = '';
      this.isPendingExchangeBuy = true;
      if (this.authService.isLoggedIn) {
        if (this.isLimitSelectedOrderType()) {
          this.makeExchangeBuy(+this.quantityBuy, +this.priceBuy, inputFrom);
        }
        if (this.isAuthorisedForStopLossTakeProfit && this.isStopLimitSelectedOrderType()) {
            const order = new OrderStopLimitClass('buy',
              this.dataService.getPair(),
              +this.priceBuy,
              +this.quantityBuy,
              +(+this.quantityBuy * (+this.priceBuy)).toFixed(6),
              +this.triggerBuyPrice,
              this.selectedBuyOrderCondition,
              this.selectedBuyOrderValidationPeriod,
              'Stop Limit',
              0,
              '',
              0);
            if (+this.minOrderAmount.minTradeSize > order.amount) {
              this.handleErrorsForCreatingStopLimitOrder('minTradeSize', 'stopLimitBuy');
            } else {
              this.makeExchangeStopLimitBuy(order, 'stopLimitBuy');
            }
        }
      } else {this.youAreNotLoggedInHandler(inputFrom); }
    }
  }
  public makeExchangeStopLimitBuy(order: OrderStopLimitClass, inputFrom?: string) {
    // console.log(inputFrom || 'makeExchangeStopLimitBuy');
    this.dataService.exchangeStopLimit(order)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          // console.log(res);
          if (res && res['success']) {
            try {
              const result = (res && res.success) ? res : {success: true, message: res};
              this.handleSuccess(result, inputFrom);
            } finally {
              this.dataService.refreshedStopLimitOrders.next();
            }
          } else {
            this.handleError(res, inputFrom);
        }
        }, err => this.handleError(err, inputFrom));
  }

  public makeExchangeBuy(quantity: number, price: number, inputFrom?: string) {
      this.dataService.exchangeBuy(quantity, price)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          const emittedBalances = this.profileState.getLastUserBalance();
          if (emittedBalances?.length > 0) {
            this.formattedBalances(emittedBalances);
          } else {
            this.getServerBalances();
          }
          this.handleSuccess(res, inputFrom);
          if (res.success === false) {
            this.errorMessageLimitOrder.next(res.message || res.error.message || 'error...');
          }
        }, err => {
          err.message = 'There\'s an internet connection issue on your side';
          this.handleError(err, inputFrom);
          this.errorMessageLimitOrder.next(err.error.message || err.message || 'error...');
        });
  }

  public makeExchangeBuyMarket(quantity: number, inputFrom?: string) {
      this.dataService.exchangeBuyMarket(quantity)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          const emittedBalances = this.profileState.getLastUserBalance();
          if (emittedBalances?.length > 0) {
            this.formattedBalances(emittedBalances);
          } else {
            this.getServerBalances();
          }
          this.handleSuccess(res, inputFrom);
          if (res.success === false) {
            this.errorMessageLimitOrder.next(res.message || res.error.message || 'error...');
          }
        }, err => {
          err.message = 'There\'s an internet connection issue on your side';
          this.handleError(err, inputFrom);
          this.errorMessageLimitOrder.next(err.error.message || err.message || 'error...');
        });
  }

  public subsToUpdateOrders() {
    this.dataService.SubscribeToUpdateOrders()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: SubscribeToUpdateTradesDataInterface) => {
        if (data.u) {
          const emittedBalances = this.profileState.getLastUserBalance();
          if (emittedBalances?.length > 0) {
            this.formattedBalances(emittedBalances);
          } else {
            this.getServerBalances();
          }
        }
      });
  }

  public exchangeSellDefault() {
    const inputFrom = 'inputDefault';
    if (this.selectedOrderType === 'Market' && this.quantityDefault) {
      this.makeExchangeSellMarket(+this.quantityDefault, inputFrom);
    }
    if (this.selectedOrderType === 'Ladder Limit' && this.quantityDefault) {
      this.makeExchangeSellLadderLimit(+this.data?.takeProfit, +this.data?.stopLoss,  +this.quantityDefault, inputFrom);
    }
    if (this.quantityDefault && (+this.priceDefault > 0)
        && !this.isCurrencyBlocked(this.pair)
        && (this.isLimitSelectedOrderType()
          || (this.isStopLimitSelectedOrderType() && (+this.triggerPrice > 0) && this.selectedDefaultOrderCondition))) {
      this.error.default = '';
      this.isPendingExchangeDefault = true;
      if (this.authService.isLoggedIn) {
        if (this.isLimitSelectedOrderType()) {
          this.makeExchangeSell(+this.quantityDefault, +this.priceDefault, inputFrom);
        }
        if (this.isAuthorisedForStopLossTakeProfit && this.isStopLimitSelectedOrderType()) {
          const order = new OrderStopLimitClass('sell',
            this.dataService.getPair(),
            +this.priceDefault,
            +this.quantityDefault,
            +(+this.quantityDefault * (+this.priceDefault)).toFixed(6),
            +this.triggerPrice,
            this.selectedDefaultOrderCondition,
            this.selectedDefaultOrderValidationPeriod,
            'Stop Limit',
            0,
            '',
            0);
          if (+this.minOrderAmount.minTradeSize > order.amount) {
            this.handleErrorsForCreatingStopLimitOrder('minTradeSize', inputFrom);
          } else {
            this.makeExchangeStopLimitSell(order, inputFrom);
          }
        }
      } else {this.youAreNotLoggedInHandler(inputFrom); }
    }
  }
  public exchangeSell() {
    const inputFrom = 'inputSell';
    if (this.quantitySell
        && (+this.priceSell > 0)
        && !this.isCurrencyBlocked(this.pair)
        && (this.isLimitSelectedOrderType()
          || (this.isStopLimitSelectedOrderType() && (+this.triggerSellPrice > 0) && this.selectedSellOrderCondition))) {
      this.error.sell = '';
      this.isPendingExchangeSell = true;
      if (this.authService.isLoggedIn) {
        if (this.isLimitSelectedOrderType()) {
          this.makeExchangeSell(+this.quantitySell, +this.priceSell, inputFrom);
        }
        if (this.isAuthorisedForStopLossTakeProfit && this.isStopLimitSelectedOrderType()) {
            const order = new OrderStopLimitClass('sell',
              this.dataService.getPair(),
              +this.priceSell,
              +this.quantitySell,
              +(+this.quantitySell * (+this.priceSell)).toFixed(6),
              +this.triggerSellPrice,
              this.selectedSellOrderCondition,
              this.selectedSellOrderValidationPeriod,
              'Stop Limit',
              0,
              '',
              0);
          if (+this.minOrderAmount.minTradeSize > order.amount) {
            this.handleErrorsForCreatingStopLimitOrder('minTradeSize', 'stopLimitSell');
          } else {
            this.makeExchangeStopLimitSell(order, 'stopLimitSell');
          }
        }
      } else {this.youAreNotLoggedInHandler(inputFrom); }
    }
  }
  public makeExchangeSell(quantity: number, price: number, inputFrom?: string) {
    if (quantity && (price > 0)) {
        this.dataService.exchangeSell(quantity, price)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(res => {
            const emittedBalances = this.profileState.getLastUserBalance();
            if (emittedBalances?.length > 0) {
              this.formattedBalances(emittedBalances);
            } else {
              this.getServerBalances();
            }
            this.handleSuccess(res, inputFrom);
            if (res.success === false) {
              this.errorMessageLimitOrder.next(res.message || res.error.message || 'error...');
            }
          }, err => {
            err.message = 'There\'s an internet connection issue on your side';
            this.handleError(err, inputFrom);
            this.errorMessageLimitOrder.next(err.error.message || err.message || 'error...');
          });
    }
  }
  public makeExchangeSellMarket(quantity: number, inputFrom?: string) {
    if (quantity) {
        this.dataService.exchangeSellMarket(quantity)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(res => {
            const emittedBalances = this.profileState.getLastUserBalance();
            if (emittedBalances?.length > 0) {
              this.formattedBalances(emittedBalances);
            } else {
              this.getServerBalances();
            }
            this.handleSuccess(res, inputFrom);
            if (res.success === false) {
              this.errorMessageLimitOrder.next(res.message || res.error.message || 'error...');
            }
          }, err => {
            err.message = 'There\'s an internet connection issue on your side';
            this.handleError(err, inputFrom);
            this.errorMessageLimitOrder.next(err.error.message || err.message || 'error...');
          });
    }
  }
  public makeExchangeSellLadderLimit(takeProfit: number, stopLoss: number, quantity: number, inputFrom?: string) {
    if (quantity && takeProfit && stopLoss) {
        this.dataService.exchangeSellLadderLimit(takeProfit, stopLoss, quantity)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(res => {
            const emittedBalances = this.profileState.getLastUserBalance();
            if (emittedBalances?.length > 0) {
              this.formattedBalances(emittedBalances);
            } else {
              this.getServerBalances();
            }
            this.handleSuccess(res, inputFrom);
            if (res.success === false) {
              this.errorMessageLimitOrder.next(res.message || res.error.message || 'error...');
            }
          }, err => {
            err.message = 'There\'s an internet connection issue on your side';
            this.handleError(err, inputFrom);
            this.errorMessageLimitOrder.next(err.error.message || err.message || 'error...');
          });
    }
  }
  public makeExchangeStopLimitSell(order: OrderStopLimitClass, inputFrom?: string) {
    // if (quantity && (price > 0)) {
        this.dataService.exchangeStopLimit(order)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(res => {
            // console.log(res);
            if (res && res['success']) {
              try {
                const result = (res && res.success) ? res : {success: true, message: res};
                this.handleSuccess(result, inputFrom);
              } finally {
                this.dataService.refreshedStopLimitOrders.next();
              }
            } else {
              this.handleError(res, inputFrom);
            }
          }, err => this.handleError(err, inputFrom));
    // }
  }
  public handleSuccess(res, inputFrom?: string) {
    // console.log(res);
    if (res.success) {
      this.dataService.refreshOrders.next();
      this.isPendingExchangeDefault = false;
      this.isPendingExchangeBuy = false;
      this.isPendingExchangeSell = false;
    } else {
      switch (inputFrom) {
        case 'inputDefault':
          this.isPendingExchangeDefault = false;
          this.error.default = res.message || ((res && res.error && res.error.message) ? res.error.message : '');
          break;
        case 'inputBuy':
          this.isPendingExchangeBuy = false;
          this.error.buy = res.message || ((res && res.error && res.error.message) ? res.error.message : '');
          break;
        case 'inputSell':
          this.isPendingExchangeSell = false;
          this.error.sell = res.message || ((res && res.error && res.error.message) ? res.error.message : '');
          break;
      }
    }
  }
  public handleError(err, inputFrom?: string) {
    switch (inputFrom) {
      case 'inputDefault':
        this.isPendingExchangeDefault = false;
        this.error.default = (err.error && err.error.message) ? err.error.message : (err.message || 'error...');
        break;
      case 'inputBuy':
        this.isPendingExchangeBuy = false;
        this.error.buy = (err.error && err.error.message) ? err.error.message : (err.message || 'error...');
        break;
      case 'inputSell':
        this.isPendingExchangeSell = false;
        this.error.sell = (err.error && err.error.message) ? err.error.message : (err.message || 'error...');
        break;
    }
  }

  public youAreNotLoggedInHandler(inputFrom?: string): void {
    switch (inputFrom) {
      case 'inputDefault':
        setTimeout(() => {
          this.error.default = 'You are not logged in...';
          this.isPendingExchangeDefault = false;
        }, 500);
        break;
      case 'inputBuy':
        setTimeout(() => {
          this.error.buy = 'You are not logged in...';
          this.isPendingExchangeBuy = false;
        }, 500);
        break;
      case 'inputSell':
        setTimeout(() => {
          this.error.sell = 'You are not logged in...';
          this.isPendingExchangeSell = false;
        }, 500);
        break;
    }
  }
  validateDuringInput(field: string) {
    let value: string;
    switch (field) {
      case 'priceDefault':
        value = this.validationService.getNumberWithDecimalsFromString(this.priceDefault);
        this.priceDefault = value;
        this.priceDefaultInputElement.nativeElement.value = value;
        break;
      case 'priceBuy':
        value = this.validationService.getNumberWithDecimalsFromString(this.priceBuy);
        this.priceBuy = value;
        this.priceBuyInputElement.nativeElement.value = value;
        break;
      case 'priceSell':
        value = this.validationService.getNumberWithDecimalsFromString(this.priceSell);
        this.priceSell = value;
        this.priceSellInputElement.nativeElement.value = value;
        break;
      case 'triggerPrice':
        value = this.validationService.getNumberWithDecimalsFromString(this.triggerPrice);
        this.triggerPrice = value;
        this.triggerPriceInputElement.nativeElement.value = value;
        break;
      case 'triggerBuyPrice':
        value = this.validationService.getNumberWithDecimalsFromString(this.triggerBuyPrice);
        this.triggerBuyPrice = value;
        this.triggerBuyPriceInputElement.nativeElement.value = value;
        break;
      case 'triggerSellPrice':
        value = this.validationService.getNumberWithDecimalsFromString(this.triggerSellPrice);
        this.triggerSellPrice = value;
        this.triggerSellPriceInputElement.nativeElement.value = value;
        break;
    }
  }

  validateDecimalNumberOnFocusOut(field: string) {
    let value: string;
    switch (field) {
      case 'priceDefault':
        value = Number(this.validationService.getNumberWithoutLastCommaOrDotFromString(this.priceDefault)).toFixed(8);
        if (+value === 0) {
          this.priceDefault = '';
          this.priceDefaultInputElement.nativeElement.value = '';
        } else {
          this.priceDefault = value;
          this.priceDefaultInputElement.nativeElement.value = value;
        }
        break;
      case 'priceBuy':
        value = this.validationService.getNumberWithoutLastCommaOrDotFromString(this.priceBuy);
        if (+value === 0) {
          this.priceBuy = '';
          this.priceBuyInputElement.nativeElement.value = '';
        } else {
          this.priceBuy = value;
          this.priceBuyInputElement.nativeElement.value = value;
        }
        break;
      case 'priceSell':
        value = this.validationService.getNumberWithoutLastCommaOrDotFromString(this.priceSell);
        if (+value === 0) {
          this.priceSell = '';
          this.priceSellInputElement.nativeElement.value = '';
        } else {
          this.priceSell = value;
          this.priceSellInputElement.nativeElement.value = value;
        }
        break;
      case 'triggerPrice':
        value = Number(this.validationService.getNumberWithoutLastCommaOrDotFromString(this.triggerPrice)).toFixed(8);
        if (+value === 0) {
          this.triggerPrice = '';
          this.triggerPriceInputElement.nativeElement.value = '';
        } else {
          this.triggerPrice = value;
          this.triggerPriceInputElement.nativeElement.value = value;
        }
        break;
      case 'triggerBuyPrice':
        value = this.validationService.getNumberWithoutLastCommaOrDotFromString(this.triggerBuyPrice);
        if (+value === 0) {
          this.triggerBuyPrice = '';
          this.triggerBuyPriceInputElement.nativeElement.value = '';
        } else {
          this.triggerBuyPrice = value;
          this.triggerBuyPriceInputElement.nativeElement.value = value;
        }
        break;
      case 'triggerSellPrice':
        value = this.validationService.getNumberWithoutLastCommaOrDotFromString(this.triggerSellPrice);
        if (+value === 0) {
          this.triggerSellPrice = '';
          this.triggerSellPriceInputElement.nativeElement.value = '';
        } else {
          this.triggerSellPrice = value;
          this.triggerSellPriceInputElement.nativeElement.value = value;
        }
        break;
    }
  }

  public loadExchangeAmount() {
    let exchangeAmount = this.dataService.getExchangeAmount();
    // console.log('exchangeAmount', exchangeAmount);
    if (exchangeAmount && exchangeAmount.length) {
      this.quantityDefault = exchangeAmount;
      this.quantitySell = exchangeAmount;
      this.quantityBuy = exchangeAmount;
    }
    this.dataService.getExchangeAmountEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(amount => {
        // console.log('getExchangeAmountEmitter', amount);
        exchangeAmount = amount;
        // console.log('exchangeAmount', exchangeAmount);
        if (exchangeAmount && exchangeAmount.length) {
          this.quantityDefault = exchangeAmount;
          this.quantitySell = exchangeAmount;
          this.quantityBuy = exchangeAmount;
        }
      });
  }
  public loadExchangePrice() {
    let exchangePrice = this.dataService.getExchangePrice();
    // console.log('exchangePrice', exchangePrice);
    if (exchangePrice && exchangePrice.length) {
      this.priceDefault = exchangePrice;
      this.priceBuy = exchangePrice;
      this.priceSell = exchangePrice;
      this.triggerPrice = exchangePrice;
      this.triggerSellPrice = exchangePrice;
      this.triggerBuyPrice = exchangePrice;
    }
    this.dataService.getExchangePriceEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(price => {
        exchangePrice = price;
        // console.log('exchangePrice', exchangePrice);
        if (exchangePrice && exchangePrice.length) {
          this.priceDefault = exchangePrice;
          this.priceBuy = exchangePrice;
          this.priceSell = exchangePrice;
          this.triggerPrice = exchangePrice;
          this.triggerSellPrice = exchangePrice;
          this.triggerBuyPrice = exchangePrice;
        }
        if (exchangePrice === ' ') {
          this.priceDefault = '';
          this.priceBuy = '';
          this.priceSell = '';
        }
      });
  }

  public showCheckedDirection(directionId: any) {
    this.checkedCalculatorDirection = +directionId;
    setTimeout(() => this.calculate(), 10);
  }

  public getBlockedMarkets(): void {
    this.dataService.getBlockedMarkets()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: Array<string>) => {
        // this.pair = this.dataService.getPair();
        this.pair = this.dataService.market;
        if (res.length) {
          this.blockedMarkets = res;
        }
      }, error => {
        console.log(error);
      });
  }

  public isCurrencyBlocked(currency: string): boolean {
    return this.blockedMarkets.includes(currency);
  }

  public prepareStopLossTakeProfitView() {
    // this.orderTypes = ['MARKET', 'Limit'];
    // const userId = this.dataService.getUserId();
    // const stopLimitTesters = [ 1306, 370 ];
    // this.orderTypes = userId && stopLimitTesters.includes(userId) ? [
    //   'Limit',
    //   'STOP_LIMIT'
    // ] : [
    //   'Limit'
    // ];
    this.orderTypes = [
      'Limit',
/*
      'Market',
      'Ladder Limit'
*/
    ];

    this.selectedOrderType = 'Limit';
  }

  private getСurrencyLastPrice(): void {
    this.сurrencyLastPrice = this.dataService.getСurrencyLastPrice();
    this.dataService.getСurrencyLastPriceEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((сurrencyLastPrice: CurrencyLastPriceClass) => {
        this.сurrencyLastPrice = сurrencyLastPrice;
        // console.log(this.сurrencyLastPrice);
        // this.checkTriggerPrice(сurrencyLastPrice, this.ordersStopLimit, this.statusesOrdersStopLimit, this.isComparedPrevLastPrice);
      });
  }

  private checkTriggerPrice(сurrencyLastPrice: CurrencyLastPriceClass,
                            _stopLimitOrders: Array<OrderStopLimitClass>,
                            statusesOrdersStopLimit: Array<OrderStopLimitStatusClass>,
                            isComparedPrevLastPrice: boolean) {
    if (_stopLimitOrders && _stopLimitOrders.length) {
      console.log('lastPrice', сurrencyLastPrice.lastPrice);
      const lastPrice = сurrencyLastPrice.lastPrice;
      const ordersStopLimit = _stopLimitOrders.filter(order => {
        return order.isCancelled === 0 && order.executed === 0 && order.pair === сurrencyLastPrice.pair;
      });
      if (ordersStopLimit.length) {
        for (let i = 0; i < ordersStopLimit.length; i++) {
          const order = ordersStopLimit[i];
          const statusArr = statusesOrdersStopLimit.filter(st => st.orderId === order.id);
          if (statusArr.length > 1) {
            console.log('=======', statusArr);
          }
          const status = statusArr[0];
          if (status.isInitiatedComparison) {
            switch (+order.triggerPrice === +lastPrice) {
              case true:
                // this.makeNewOrderOnPriceTriggering(order);
                console.log('triggerPrice === lastPrice', status);
                break;
              default:
                status.isComparedCurrentLastPrice = +lastPrice > +order.triggerPrice;
                status.currensLastPrice = +lastPrice;
                if (status.isComparedCurrentLastPrice === !status.isComparedPreviousLastPrice) {
                  // this.makeNewOrderOnPriceTriggering(order);
                  console.log('lastPrice jumped triggerPrice !  ', status);
                } else {
                  this.isComparedPrevLastPrice = status.isComparedCurrentLastPrice;
                }
                this.statusesOrdersStopLimit = this.updateStatusForStopLimit(this.statusesOrdersStopLimit, status);
                break;
            }
          } else {
            status.isComparedPreviousLastPrice = +lastPrice > +order.triggerPrice;
            status.previousLastPrice = +lastPrice;
            status.isInitiatedComparison = true;
            this.statusesOrdersStopLimit = this.updateStatusForStopLimit(this.statusesOrdersStopLimit, status);
          }

        }
      }
    }
  }

  private makeNewOrderOnPriceTriggering(order: OrderStopLimitClass) {
    // console.log('triggerPrice = ' + order.triggerPrice);
    // console.log(order);
    const amount = +order.amount;
    const price = +order.price;
    let inputFrom: string;
    inputFrom = this.dataService.currentTheme === ThemesModel.DEFAULT
      ? 'inputDefault'
      : (order.direction.toLowerCase() === 'buy' ? 'inputBuy' : 'inputSell');
    this.deleteStopLimitOrder(order.id, '', inputFrom);
    switch (order.direction.toLowerCase()) {
      case 'buy':
        this.makeExchangeBuy(amount, price, inputFrom);
        break;
      case 'sell':
        this.makeExchangeSell(amount, price, inputFrom);
        break;
    }
    this.dataService.refreshedStopLimitOrders.next();
    this.dataService.refreshOrders.next();
    this.errorMessageLimitOrder
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(message => {
        console.log(message);
        this.deleteStopLimitOrder(order.id, message, inputFrom);
        setTimeout(() => {
          this.dataService.refreshedStopLimitOrders.next();
          this.dataService.refreshOrders.next();
          this.clearErrors();
        }, 0);
      });
  }

  public deleteStopLimitOrder(orderId, message?: string, inputFrom?: string) {
    this.dataService.deleteStopLimitOrder(orderId, {executed: 1, reason: message || ''})
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(res => {
          // console.log(res);
          // this.getOrders();
        },
        err => {
          this.handleError(err, inputFrom || '');
          console.log('deleteStopLimitOrder error', err);
        }
      );
  }

  public getStopLimitOrders(userId: number, from?: any) {
    // console.log(from || '?');
    this.dataService.getOpenStopLimitOrders(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: Array<OrderStopLimitClass>) => {
        const ordersStopLimit = res.filter(order => {
          return (order.isCancelled === 0 && order.executed === 0 && order.pair === this.dataService.getPair());
        });
        // console.log('active', ordersStopLimit);
        // console.log(this.statusesOrdersStopLimit);
        this.statusesOrdersStopLimit = this.updateStatuses(this.statusesOrdersStopLimit, this.ordersStopLimit, ordersStopLimit);
        // console.log(this.statusesOrdersStopLimit);
        this.ordersStopLimit = ordersStopLimit;
      });
  }

  private updateStatusForStopLimit(_statusesOrders: Array<OrderStopLimitStatusClass>,
                                   status: OrderStopLimitStatusClass): Array<OrderStopLimitStatusClass> {
    const statusesOrders = [..._statusesOrders];
    let index;
    _statusesOrders.forEach((_status, _index) => {
      if (_status.orderId === status.orderId) {index = _index; }
    });
    statusesOrders.splice(index, 1, status);
    return statusesOrders;
  }

  private updateStatuses(_statusesOrders: Array<OrderStopLimitStatusClass>,
                         previousStopLimitOrders: Array<OrderStopLimitClass>,
                         newStopLimitOrders: Array<OrderStopLimitClass>): Array<OrderStopLimitStatusClass> {
    const arrOrdersId = [];
    const arrStatusesId = [];
    const statusesOrders = Array<OrderStopLimitStatusClass>(0);
    newStopLimitOrders.forEach(order => arrOrdersId.push(+order.id));
    _statusesOrders.forEach(status => arrStatusesId.push(+status.orderId));
    _statusesOrders.forEach(status => {
      if (arrOrdersId.includes(status.orderId)) {statusesOrders.push(status); }
    });
    newStopLimitOrders.forEach(newOrder => {
      if (!arrStatusesId.includes(newOrder.id)) {
        statusesOrders.push(new OrderStopLimitStatusClass(
          newOrder.id,
          newOrder.direction,
          newOrder.pair,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          newOrder.triggerPrice,
          newOrder.operateWhen
        ));
      }
        });
    return statusesOrders;
  }

  public comparer(otherArray) {
    return function(current) {
      return otherArray.filter(function(other) {
        return other.value === current.value && other.display === current.display;
      }).length === 0;
    };
  }

  public toggleClass(): void {
    this.activeList = !this.activeList;
  }
  public toggleDefaultTriggerConditionListClass(): void {
    this.activeDefaultTriggerConditionList = !this.activeDefaultTriggerConditionList;
  }
  public toggleBuyTriggerConditionListClass(): void {
    this.activeBuyTriggerConditionList = !this.activeBuyTriggerConditionList;
  }
  public toggleSellTriggerConditionListClass(): void {
    this.activeSellTriggerConditionList = !this.activeSellTriggerConditionList;
  }
  public toggleDefaultOrderValidationPeriodListClass(): void {
    this.activeOrderDefaultValidationPeriodList = !this.activeOrderDefaultValidationPeriodList;
  }
  public toggleBuyOrderValidationPeriodListClass(): void {
    this.activeOrderBuyValidationPeriodList = !this.activeOrderBuyValidationPeriodList;
  }
  public toggleBuyTh3UpOrderValidationPeriodListClass(): void {
    this.activeOrderBuyTh3UpValidationPeriodList = !this.activeOrderBuyTh3UpValidationPeriodList;
  }
  public toggleSellTh3UpOrderValidationPeriodListClass(): void {
    this.activeOrderSellTh3UpValidationPeriodList = !this.activeOrderSellTh3UpValidationPeriodList;
  }
  public toggleSellOrderValidationPeriodListClass(): void {
    this.activeOrderSellValidationPeriodList = !this.activeOrderSellValidationPeriodList;
  }
  public setOrderType(orderType): void {
    this.selectedOrderType = orderType;
  }
  public setDefaultOrderCondition(condition): void {
    this.selectedDefaultOrderCondition = condition;
  }
  public setBuyOrderCondition(condition): void {
    this.selectedBuyOrderCondition = condition;
  }
  public setSellOrderCondition(condition): void {
    this.selectedSellOrderCondition = condition;
  }
  public setDefaultOrderValidationPeriod(period): void {
    this.selectedDefaultOrderValidationPeriod = period;
  }
  public setBuyOrderValidationPeriod(period): void {
    this.selectedBuyOrderValidationPeriod = period;
  }
  public setSellOrderValidationPeriod(period): void {
    this.selectedSellOrderValidationPeriod = period;
  }
  public isLimitSelectedOrderType(): boolean {
    return this.selectedOrderType === 'Limit';
  }
  public isStopLimitSelectedOrderType(): boolean {
    return this.selectedOrderType === 'STOP_LIMIT';
  }
  public volumePercentChange(v: number) {
    if (this.direction === 'buy') {
      const total = this.balanceCurrencyAdditional?.balance || 0;
      if (total === 0 || !this.priceDefault) { return; }
      this.quantityDefault = ((total / +this.priceDefault) * v * 0.998).toFixed(8);
    } else {
      this.quantityDefault = `${(+this.balanceCurrencyMain?.balance || 0) * v}`;
    }
  }

  public clearErrors(): void {
    this.error.default = '';
    this.error.buy = '';
    this.error.sell = '';
  }

  formatNumber(number: number) {
    const numberString = number.toString();

    if (numberString.includes('+')) {
      return number;
    } else if (numberString.indexOf('.') === -1) {
      return number;
    } else {
      const decimalPart = numberString.split('.')[1];
      if (decimalPart.length > 8) {
        return number.toFixed(8);
      }
    }
    return number;
  }

  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }

  selectButton(button: HTMLElement) {
    this.selectedButton = button;
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';

import axios from 'axios';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  // _apiEndpoint: string = environment.host;
  _apiEndpoint = 'https://test-api.intebix.kz/';
  httpOptions;
  httpBlobOptions;
  httIPOptions;
  constructor(private http: HttpClient) {
    this.makeHeaders();
  }

  public post(url: string, data?: any): Observable<any> {
    return this.http.post(this._apiEndpoint + url, data, this.httpOptions);
  }

  public postMultipartFormData(url: string, data: any): Promise<any> {
    const token = JSON.parse(sessionStorage.getItem('jwtToken')) || JSON.parse(localStorage.getItem('jwtToken'));
    const options = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: (token && token.accessToken) ? token.accessToken : ''
      }
    };
    return axios.post(this._apiEndpoint + url, data, options);
  }

  public get(url: string): Observable<any> {
    return this.http.get(this._apiEndpoint + url, this.httpOptions);
  }

  public getBlob(url: string): Observable<any> {
    return this.http.get(this._apiEndpoint + url, this.httpBlobOptions);
  }

  public getJsonP(url: string): Observable<any> {
    // return this.http.jsonp(url, 'callback');
    return this.http.get(url + '?token=b07963928c377f');
    // return this.http.get(url + '&callback=JSONP_CALLBACK', this.httIPOptions);
    // return this.http.get(url + '?callback=callback', this.httIPOptions);
    // return this.jsonp.request(url, this.httIPOptions);
  }

  public put(url: string, data: any): Observable<any> {
    return this.http.put(this._apiEndpoint + url, data, this.httpOptions);
  }

  public patch(url: string, data: any): Observable<any> {
    return this.http.patch(this._apiEndpoint + url, data, this.httpOptions);
  }

  public deleteRequest(url: string): Observable<any> {
    return this.http.delete(this._apiEndpoint + url, this.httpOptions);
  }
  public delete(url: string, data: any): Observable<any> {
    return this.http.request('delete', this._apiEndpoint + url, { body: data, headers: this.httpOptions.headers });
  }

  public upload(url: string, data: any): Observable<any> {
    return this.http.post(this._apiEndpoint + url, data, this.httpOptions);
  }

  public makeHeaders(): void {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.httIPOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'token': 'b07963928c377f'
      })
    };
    this.httpBlobOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/csv'
      }),
      responseType: 'blob',
    };
  }

}

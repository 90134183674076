import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-crypto-credential-sending',
  templateUrl: './crypto-credential-sending.component.html',
  styleUrls: ['./crypto-credential-sending.component.scss']
})
export class CryptoCredentialSendingComponent implements OnInit {
  selectedOption;
  selectedTokenProps;
  startingSterp = true;
  sendMoneyStep = false;

  constructor()  {
  }

  coins = [
    {
      imgSrc: 'assets/landing/icons/bitcoin_icon.svg',
      shortName: 'BTC',
      fullName: 'Bitcoin'
    },
    {
      imgSrc: 'assets/landing/icons/bitcoin_cash_icon.svg',
      shortName: 'BCH',
      fullName: 'Bitcoin cash'
    },
    {
      imgSrc: 'assets/landing/icons/cardano_icon.svg',
      shortName: 'ADA',
      fullName: 'Cardano'
    },
    {
      imgSrc: 'assets/landing/icons/ethereum_icon.svg',
      shortName: 'ETH',
      fullName: 'Ethereum'
    },
    {
      imgSrc: 'assets/landing/icons/ripple_icon.svg',
      shortName: 'XRP',
      fullName: 'Ripple'
    },
    {
      imgSrc: 'assets/landing/icons/tether_icon.svg',
      shortName: 'USDT',
      fullName: 'Tether'
    },
  ];
  ngOnInit() {
    console.log('on init');
  }

  getCurrentDetailInfo() {
    return '';
  }

  getCurrencyName() {
    return '';
  }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewSelectComponent } from './components/new-select/new-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { NewInputComponent } from './components/new-input/new-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegExpDirective } from './directives/reg-exp.directive';

@NgModule({
  declarations: [
    NewSelectComponent,
    NewInputComponent,

    // directives
    RegExpDirective,
  ],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormsModule],
  exports: [
    NewSelectComponent,
    NewInputComponent,

    // directives
    RegExpDirective,
  ],
})
export class SharedModule {}

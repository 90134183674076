import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../../services/data.service';
import {HomeSearchPipe} from '../../../pipes/home/home-search.pipe';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {MarketPair} from '../../../interfaces/market-pair';

enum MarketEnum {
  BTC = 'BTC',
  ETC = 'ETH',
  USDT = 'USDT',
  All = 'All'
}

@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',
  styleUrls: ['./market-list.component.scss'],
  providers: [HomeSearchPipe]
})
export class MarketListComponent implements OnChanges, OnDestroy {
  @Input() marketName;
  @Input() marketListPairs;
  @Input() marketBtc;
  @Input() marketEtc;
  @Input() marketUSDT;
  @Input() marketAll;
  @Input() popularCoinList;

  // new
  @Input() marketNames;
  @Input() marketListFav;
  // public _marketsData: any;
  public isLightTheme = false;
  public pagination = 0;
  public marketsList;
  public searchText;

  currentMarket = 'All';

  public sortParams = {
    sortByVolume: '',
    sortByPair: '',
    sortByCurrency: '',
    sortByChange24h: '',
  };
  destroySubject$: Subject<void> = new Subject();

  marketSymbols: Record<MarketEnum, string> = {
    [MarketEnum.BTC]: '₿',
    [MarketEnum.ETC]: 'Ξ',
    [MarketEnum.USDT]: '₮',
    [MarketEnum.All]: '',
  };

  get marketSymbol() {
    return this.marketSymbols[this.currentMarket];
  }

  constructor(public dataService: DataService,
              public authService: AuthService,
              public router: Router,
  ) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
    this.dataService.isMarketListPageSubject.next(true);
  }

  getMarketSymbol(market) {
    return this.marketSymbols[market.MarketName.split('-')[0]] || '';
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dataService.isMarketListPageSubject.next(false);
  }

  getMarkets() {
    const name = this.currentMarket;
    if (name === MarketEnum.BTC) {
      return this.marketBtc;
    } else if (name === MarketEnum.ETC) {
      return this.marketEtc;
    } else if (name  === MarketEnum.USDT) {
      return this.marketUSDT;
    } else if (name  === MarketEnum.All) {
      return this.marketAll;
    } else {
      return this.marketListFav;
    }
  }

  setMarketsData(name: any): void {
    this.currentMarket = name;
    this.searchInMarketsList(false);
  }

  ngOnChanges(): void {
    // this._marketsData = JSON.parse(JSON.stringify(this.marketsData));
    this.searchInMarketsList(false);
  }
  private paginationHell(data) {
    const size = 20;
    const subarray = [];
    const paginationArray = [];
    for (let i = 0; i < Math.ceil(data.length / size); i++) {
      subarray[i] = data.slice((i * size), (i * size) + size);
      paginationArray.push(i);
    }
    subarray.push(paginationArray);

    return subarray;
  }

  public searchInMarketsList(isChangedInputSearch: boolean): void {
    if (isChangedInputSearch) {
      this.pagination = 0;
    }

    this.marketsList = (this.searchText)
                      ? this.paginationHell(this.homeSearchPipeCalling(this.getMarkets(), this.searchText))
                      : this.paginationHell(this.getMarkets());

  }
  public homeSearchPipeCalling(dataArray: Array<any>, searchText: string): Array<any> {
    return new HomeSearchPipe().transform(this.getMarkets(), this.searchText);
  }

  public checkForBitcoinName(data: string, pair?: string): string {
    let result = '';
    switch (data.toLowerCase()) {
      case 'bitcoin':
        result = (pair.toUpperCase() === 'USDT-BTC' || pair.toUpperCase() === 'EUR-BTC') ? 'Bitcoin' : 'Bitcoin Cash';
        break;
      default: result = data;
    }
    switch (pair.toUpperCase().split('-')[1]) {
      case 'XRP':
        result = 'XRP';
        break;
      case 'BSV':
        result = ' Bitcoin SV';
        break;
      case 'BAT':
        result = ' Basic Attention Token';
        break;
      case 'DOT':
        result = 'Polkadot';
        break;
      // default: result = data;
    }
    return result;
  }
  public navigateToTrades(pair): void {
    this.dataService.storePair(pair);
    this.router.navigate([`trades/${pair}`]).then();
  }
  public iteration(plus): void {
    plus ? this.pagination++ : this.pagination--;
  }
  public changeSort(): void {
    // if (this.sortParam === 'off')
    //   this.sortParam = 'up';
    // else if (this.sortParam === 'up')
    //   this.sortParam = 'down';
    // else this.sortParam = 'off';
  }
  public toggleSort(param) {
    // console.log('current ' + param, this.sortParams[param]);
    // this.toggleDefaultSort();
    // switch (this.sortParams[param]) {
    //   case 'up':
    //     this.sortParams[param] = 'down';
    //     break;
    //   case 'down':
    //     this.sortParams[param] = 'up';
    //     break;
    //   default: this.sortParams[param] = 'down';
    // }
    // console.log(this.sortParams);
    // console.log('-----');

    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = 'up';
    } else {
      this.sortParams[param] = 'up';
    }
  }
  public toggleDefaultSort() {
  this.sortParams = {
      sortByVolume: '',
      sortByPair: '',
      sortByCurrency: '',
      sortByChange24h: ''
    };

  }

  formattedMarketName(marketName: string): string {
    return marketName?.split('-')?.reverse()[0];
  }

  formatMarketName(marketName: string) {
    const updateMarketName = marketName?.split('-')?.reverse()[0];
    switch (updateMarketName) {
      case 'BTC':
        return 'Bitcoin';
      case 'ADA':
        return 'Cardano';
      case 'BCH':
        return 'Bitcoin Cash';
      case 'XRP':
        return 'Ripple';
      case 'ETH':
        return 'Ethereum';
    }
  }

  formattedMarketTradeName(marketName: string): string {
    return marketName?.split('-')?.reverse()?.join('-');
  }

  generateImgSrc(name: string): string {
    // return `assets/img/coins/${name?.split('-')[1]?.toLowerCase()}.png`;
    const coin = name?.split('-')[1];
    switch (coin) {
      case 'BTC':
        return 'assets/landing/icons/bitcoin_icon.svg';
      case 'ADA':
        return 'assets/landing/icons/cardano_icon.svg';
      case 'BCH':
        return 'assets/landing/icons/bitcoin_cash_icon.svg';
      case 'XRP':
        return 'assets/landing/icons/ripple_icon.svg';
      case 'ETH':
        return 'assets/landing/icons/ethereum_icon.svg';
    }
    // return `assets/img/coins/${name?.split('-')[1]?.toLowerCase()}.png`;
  }

  formatNumber(value: number, toFixedValue: number) {
    const [integerPart, decimalPart] = value.toFixed(toFixedValue).split('.');

    const integerPartToLocaleString = parseInt(integerPart, 10).toLocaleString('en-US');

    return `${integerPartToLocaleString}.${decimalPart}`;
  }

  public toggleFav(market: string, isFav: boolean, event: PointerEvent): void {
    event.stopPropagation();
    const data = {market_name: market };
    if (isFav) {
      this.dataService.removeFromFavoriteMarkets(data)
        .subscribe();
    } else {
      this.dataService.addToFavoriteMarkets(data).subscribe();
    }
  }

  getSrcFav(market): string {
    return `assets/img/${this.marketListPairs.includes(market) ? 'favorite-star-active' : 'favorite-star-no-active'}.svg`;
  }
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../../environments/environment';
import {BaseService} from './base.service';

import * as socketIo from 'socket.io-client';
import {CurrencyInterface} from '../models/currency.model';
import {ThemesModel} from '../models/themes.model';
import {MarketsShortInfoInterface} from '../models/markets-short-info.model';
import {
  IS_GEO_TURNED_ON,
  RESTRICTED_CITIES,
  RESTRICTED_COUNTRIES,
  USERS_AUTHORISED_FOR_FIAT_MODAL,
  USERS_AUTHORISED_FOR_STOP_LOSS,
} from '../app.constants';
import {RestrictedCountriesClass} from '../models/restricted-countries.class';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {MyProfileInterface} from '../models/my-profile.model';
import {CountryClass} from '../models/country.class';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {FiatClass} from '../models/fiat.class';
import {TickHistoryModel} from '../models/tick-history.model';
import {CurrencyLastPriceClass} from '../models/currency-last-price.class';
import {OrderStopLimitClass} from '../models/order-stop-limit.class';
import {BCXGRedemption} from '../models/bcxg-redemption.model';
import { map, shareReplay, switchMap, tap, throttleTime} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {BalanceInterface} from '../models/balance.model';
import {concat} from 'rxjs/internal/observable/concat';
import socket from '../../assets/tradingview/charting_library/socket';
import Io from '../../assets/tradingview/charting_library/socket';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {ISelectOption} from '../ui/custom-select/custom-select.component';
import {WalletBalanceInterface} from '../models/wallet-balance.model';
import {WalletFeeCurrencyInterface} from '../models/wallet-fee-currency.model';
import {fromPromise} from 'rxjs/internal-compatibility';
import {EukzSpendsDto} from './eukz-spends';
import { IMarketList } from '../interfaces/market-list.interface';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public isMarketListPageSubject = new BehaviorSubject(false);
  public market = localStorage.getItem('bitPair') ? localStorage.getItem('bitPair') : 'BTC-ETH';
  public market$: BehaviorSubject<string> = new BehaviorSubject(this.market);
  public SERVER_URL: any;
  private socket: any;
  private orderBookHistory = new Subject<any>();
  private headers: Headers;
  public refreshOrders = new Subject();
  public reCaptcha_version = environment.name || 'prod';
  public currentTheme: string = ThemesModel.DEFAULT;
  public currentThemeSubject = new BehaviorSubject<string>('Theme 1');
  public authStatus$ = new BehaviorSubject<boolean>(false);

  currenciesList = new Subject<Array<CurrencyInterface>>();
  currenciesListData: Array<CurrencyInterface>;

  marketPairMinOrderAmount = new Subject<MarketsShortInfoInterface>();
  marketPairMinOrderAmountData: MarketsShortInfoInterface;

  marketPairNew = new Subject<string>();
  public refreshedStopLimitOrders = new Subject();

  exchangePrice = new Subject<string>();
  exchangePriceData: string;

  exchangeAmount = new Subject<string>();
  exchangeAmountData: string;

  loginIncomeErrorStatus = new Subject<Array<string>>();
  loginIncomeErrorStatusData: Array<string>;

  messageTypeForAlertModal = new Subject<number>();
  messageTypeForAlertModalData: number;

  isLightTheme = new Subject<boolean>();
  isLightThemeData: boolean;

  messageTypeForGlobalMessage = new Subject<number>();
  messageTypeForGlobalMessageData: number;

  totalDepositUSDT = new Subject<number>();
  totalDepositUSDTData: number;

  ticksHistory = new Subject<Array<TickHistoryModel>>();
  ticksHistoryData: Array<TickHistoryModel>;

  currencyLastPrice: CurrencyLastPriceClass;
  currencyLastPriceData = new Subject<CurrencyLastPriceClass>();

  private _lastMarketsDetails = new Map<string, any>();

  marketsDetails$ = concat(
    this.getMarketsList(),
    this.SubscribeToSummaryDeltas(),
  ).pipe(
    map(
      (markets) => markets.map(
        (v) => this._mergeMarketField(this._lastMarketsDetails.get(v.MarketName), v),
      )
    ),
    tap((markets) => this._lastMarketsDetails = new Map<string, any>(markets.map((v) => [v.MarketName, v]))),
    shareReplay(1)
  );

  marketsDetailsForTrades$ = concat(
    this.getMarketsList(),
    this.SubscribeToSummaryDeltasWithoutDelay(),
  ).pipe(
    map(
      (markets) => markets.map(
        (v) => this._mergeMarketField(this._lastMarketsDetails.get(v.MarketName), v),
      )
    ),
    tap((markets) => this._lastMarketsDetails = new Map<string, any>(markets.map((v) => [v.MarketName, v]))),
    shareReplay(1)
  );

  currentMarketDetails$ = combineLatest([
    this.marketsDetailsForTrades$,
    this.market$.pipe(
      switchMap(() => this.getMarketHistory())
    )
  ]).pipe(
    map(([markets, marketHistory]) => {
      const market = markets.find((m) => m.MarketName === this.market);
      return {
        ...market,
        // lastUp: (markets.lastUp || market.lastDown)
        //   ? market.lastUp : this._getLastDifferentPrice(marketHistory, market.Last) < market.Last,
        // lastDown: (markets.lastUp || market.lastDown)
        //   ? market.lastDown : this._getLastDifferentPrice(marketHistory, market.Last) > market.Last,
      };
    }),
    // tap((v) => console.log('summary delats for market', v.Last)),
    shareReplay(1),
  );

  currencyOptions$: Observable<ISelectOption<WalletBalanceInterface>[]> = this.getBalances().pipe(
    map((data: Array<WalletBalanceInterface>) => data.map(
      (v) => ({
        label: `${v.currency} (${v.currencyBase})`,
        value: v,
        iconSrc: `/assets/img/coins/${v.currency?.toLowerCase()}.png`
      })
    ))
  );

  constructor(
    public base: BaseService,
    private http: HttpClient,
    public router: Router
  ) {
    this.SERVER_URL = environment.host;
    this.socket = socketIo(this.SERVER_URL);
    this.headers = new Headers({'Content-Type': 'application/jsonp'});
    this.setCurrentTheme(ThemesModel.DEFAULT);
  }

  private _getLastDifferentPrice(history: Array<{rate?: number}>, price: number) {
    return history?.find((v) => v.rate !== price)?.rate || price;
  }

  private _mergeMarketField = (last: any, current: any) => {
    const mergedKeys = new Set([...Object.keys(last || {}), ...Object.keys(current)]);
    return {...Array.from(mergedKeys.values()).reduce(
      (acc, k) => ({...acc, [k]: current[k] || (last || {})[k]}), {}
    ),
      lastUp: last
        ? (+last.Last < +current.Last || (+last.lastUp && +last.Last === +current.Last))
        : +current.Last > +current.prevPrice,
      lastDown: last
        ? (+last.Last > +current.Last || (+last.lastDown && +last.Last === +current.Last))
        : +current.Last < +current.prevPrice,
      BaseVolumeUSDT: current.MarketName.split('-')[0] === 'USDT' ? current.BaseVolume24 : current.BaseVolumeUSDT,
    };
  }

  public storePair(pair: string) {
    localStorage.setItem('bitPair', pair);
    this.market = pair;
    this.market$.next(this.market);
  }

  public getPair(): string {
    return (this.market) ? this.market : (localStorage.getItem('bitPair') ? localStorage.getItem('bitPair') : '');
  }

  public joinRoom() {
    // console.log('joinedRoom ' + this.market);
    this.socket.emit('joinRoom', this.market);
  }

  public joinRoomUserId(id: string) {
    // console.log('joinedRoom ' + 'u_' + id);
    this.socket.emit('joinRoom', 'u_' + id);
  }

  public leaveRoom() {
    // console.log('leavedRoom ' + this.market);
    this.socket.emit('leaveRoom', this.market);
  }

  public leaveRoomUserId(id: string) {
    // console.log('leavedRoom ' + 'u_' + id);
    this.socket.emit('leaveRoom', 'u_' + id);
  }

  public SubscribeMarketHistory() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeMarketHistory', data => observer.next(data));
    });
  }

  // public getTicker() {
  //   return new Observable<any>(observer => {
  //     this.socket.on('getTicker', data => {
  //       observer.next(data);
  //     });
  //   });
  // }

  // public SubscribeToExchangeDeltas() {
  //   return new Observable<any>(observer => {
  //     this.socket.on('SubscribeToExchangeDeltas', data => observer.next(data));
  //   });
  // }

  public SubscribeToOrderbookUpdates() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeToOrderbookUpdates', data => observer.next(data));
    });
  }

  public EmitSubscribeToOrderbookUpdates() {
    this.socket.emit('subscribeToOrderbookUpdates', {marketSymbol: this.market});
  }

  public EmitUnsubscribeToOrderbookUpdates() {
    this.socket.emit('unsubscribeToOrderbookUpdates', {marketSymbol: this.market});
  }

  public SubscribeToTradeUpdates() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeToTradeUpdates', data => observer.next(data));
    });
  }

  public EmitSubscribeToTradeUpdates() {
    this.socket.emit('subscribeToTradeUpdates', {marketSymbol: this.market});
  }

  public EmitUnsubscribeToTradeUpdates() {
    this.socket.emit('unsubscribeToTradeUpdates', {marketSymbol: this.market});
  }

  public SubscribeOpenOrders() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeOpenOrders', data => observer.next(data));
    });
  }

  public EmitSubscribeToSummaryDeltas() {
    this.socket.emit('subscribeToSummaryDeltas', {});
  }

  public SubscribeToSummaryDeltas() {
    return new Observable<IMarketList[]>((observer) => {
      this.socket.on('SubscribeToSummaryDeltas', (data) => {
        observer.next(data);
      });
    }).pipe(throttleTime(5000));
  }

  public SubscribeToSummaryDeltasWithoutDelay() {
    return new Observable<any>(observer => {
      this.socket.on('SubscribeToSummaryDeltas', data => observer.next(data));
    });
  }

  public SubscribeToUserBalances() {
    return new Observable<Array<BalanceInterface>>(observer => {
      this.socket.on('SubscribeToUserBalanceUpdates', data => observer.next(data));
    });
  }

  public SubscribeToUpdateOrders() {
    return new Observable<any>(observer => {
      this.socket.on('ud', data => observer.next(data));
    });
  }

  public SubscribeToUpdateFavs() {
    return new Observable<any>(observer => {
      this.socket.on('uf', data => observer.next(data));
    });
  }

  public SubscribeToUpdateBalances() {
    return new Observable<any>(observer => {
      this.socket.on('ub', data => observer.next(data));
    });
  }

  public SubscribeToCandleUpdates(interval) {
    const pair = this.getPair();
    this.socket.emit('joinRoom', `${pair}_${interval}`);
    return new Observable<any>(observer => {
      this.socket.on('candleUpdates', (data) => observer.next(data));
    });
  }

  public emitCandleUpdates(data) {
    this.socket.emit('subscribeToCandleUpdates', data);
  }

  public emitUnsubscribeCandleUpdates(data) {
    this.socket.emit('unsubscribeToCandleUpdates', data);
  }

  public downloadFile(apiUrl: string, fileName: string) {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    return this.base.getBlob(apiUrl).subscribe(
      (blob) => {
        const objectUrl = window.URL.createObjectURL(blob);
        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      }
    );
  }

  public getMarketHistory(): Observable<any> {
    return this.base.get(`api/v1/bitt/market-history?market=${this.market}`);
  }

  public getFeesAndLimits(): Observable<any> {
    return this.base.get(`api/v1/user/wallets/fees`);
  }

  public getMinimalCurrencyAmount(currency: string) {
    return this.base.get(`api/v1/user/withdrawals/minimal-amount?currency=${currency}`);
  }

  public getMarketSummary(): Observable<any> {
    return this.base.get(`api/v1/bitt/market-summary?market=${this.market}`);
  }

  public getMarketPairsShortInfoServer(): Observable<any> {
    return this.base.get(`api/v1/bitt/markets-short-info?market=` + this.getPair());
  }

  public getOrderBookHistory(): Observable<any> {
    return this.base.get(`api/v1/bitt/orderbooks-history?market=${this.market}`);
  }

  public getDataByIP(ip: string): Observable<any> {
    return this.base.getJsonP('https://ipinfo.io/json');
  }

  public getTicksHistory(): Observable<any> {
    return this.base.get('api/v1/bitt/ticks-history');
  }

  public addFreeCoinsToUserWallet(id: number): Observable<any> {
    return this.base.post(`api/v1/user/spc-bonus/${id}`);
  }

  public setCookiesAgreementStatus(id: number, isAgreed: number): Observable<any> {
    return this.base.post(`api/v1/user/cookies_agreed/${id}/${isAgreed}`);
  }

  public getFavoriteMarkets(): Observable<any> {
    return this.base.get('api/v1/favorites/list');
  }

  public getCurrencyList(): Observable<any> {
    return this.base.get('api/v1/user/currencies/list');
  }

  public getCurrenciesListFromServer(): void {
    this.getCurrencyList()
      .subscribe((data: Array<CurrencyInterface>) => {
        this.passSharedCurrenciesList(data);
        this.setSharedCurrenciesList(data);
      });
  }

  public getMarketMinOrderAmount() {
    this.getMarketPairsShortInfoServer()
      .subscribe(data => {
        const marketPairMinOrderAmount = data[this.getPair()];
        this.passMarketPairMinOrderAmount(marketPairMinOrderAmount);
        this.setMarketPairMinOrderAmount(marketPairMinOrderAmount);
      });
  }


  public addToFavoriteMarkets(data): Observable<any> {
    return this.base.post('api/v1/favorites', data);
  }

  public removeFromFavoriteMarkets(data): Observable<any> {
    return this.base.delete('api/v1/favorites', data);
  }

  public getBalances(): Observable<any[]> {
    return this.getWallets().pipe(map(
      (data) => data.map((v) => ({...v, valueUSDT: v.currency === 'USDT' ? v.balance : v.valueUSDT}))
    ));
  }

  public buy(quantity, rate): Observable<any> {
    return this.base.post('api/v1/bitt/buy', {
      market: this.market,
      type: 'LIMIT',
      quantity,
      rate,
      timeInEffect: 'IMMEDIATE_OR_CANCEL',
      conditionType: 'NONE',
      target: 0
    });
  }

  public sendImagesKYC(type: string, document: any): Promise<any> {
    const input = new FormData();
    let url = '';
    switch (type) {
      case 'passport':
        url = '/kyc-passport';
        break;
      case 'selfie':
        url = '/kyc-selfie';
        break;
    }

    input.set('type', type);
    input.set('document', document);
    return this.base.postMultipartFormData(`api/v1/user${url}`, input);
  }

  // public sendPDFtoServer(url: string, body: any): Observable<any> {
  //   const headers: Headers = new Headers();
  //   this.token = localStorage.getItem('token');
  //
  //   headers.append('Content-Type', 'multipart/form-data');
  //   headers.append('Authorization', 'Bearer ' + this.token);
  //   // headers.append('Accept', 'application/json');
  //
  //   return this.http.post(url, body, {headers: headers})
  //     .map((res: Response) => <Object[]>res.json());
  // }

  public getWallets(): Observable<any[]> {
    return this.base.get('api/v1/user/wallets');
  }

  public minWithdrawalSum(currency: string): Observable<any> {
    return this.base.get(`api/v1/user/withdrawals/minimal-amount?currency=${currency}`);
  }

  public getImagesKYC(): Observable<any> {
    return this.base.get('api/v1/user/kyc-urls');
  }

  public getDepositsWithdrawals(): Observable<any> {
    return this.base.get('api/v1/user/deposits-withdrawals');
  }

  public getDeposits(): Observable<any[]> {
    return this.base.get('api/v1/user/deposits');
  }

  public getWithdrawals(): Observable<any[]> {
    return this.base.get('api/v1/user/withdrawals');
  }

  public sell(quantity, rate): Observable<any> {
    return this.base.post('api/v1/bitt/sell', {
      market: this.market,
      type: 'LIMIT',
      quantity,
      rate,
      timeInEffect: 'IMMEDIATE_OR_CANCEL',
      conditionType: 'NONE',
      target: 0
    });
  }

  public updateOrderBookData(data) {
    this.orderBookHistory.next(data);
  }

  public getOrderBookData() {
    return this.orderBookHistory.asObservable();
  }

  public registerMastercardAccountByNickname(nickname: string) {
    return this.base.post('api/v1/mastercard/account', {
      accountAlias: nickname,
    });
  }

  public registerMastercardAccountByEmail(email: string) {
    return this.base.post('api/v1/mastercard/account/email', {
      accountAlias: email,
    });
  }

  public checkMastercardAccountStatus(nickname: string) {
    return this.base.post('api/v1/mastercard/account/search', {
      accountAlias: nickname,
    });
  }

  public getTransactions(): Observable<any> {
    return this.base.get('api/v1/bitt/withdrawal-deposit');
  }

  public getWalletFeeCurrency(currency: string): Observable<{res: WalletFeeCurrencyInterface}> {
    return this.base.get('api/v1/user/wallets/fee?currency=' + currency);
  }

  public getAllCurrenciesFees(): Observable<any> {
    return this.base.get('api/v1/user/wallets/all-currencies');
  }

  public getAllCurrencieHealth(): Observable<any> {
    return this.base.get('api/v1/bitt/currencies-health');
  }

  public getBlockedMarkets(): Observable<any> {
    return this.base.get('api/v1/settings/blocked-markets');
  }

  public getServerCountries(): Observable<any> {
    return this.base.get('api/v1/settings/countries');
  }

  public getServerBlackListCountries(): Observable<any> {
    return this.base.get('api/v1/settings/black-list-countries');
  }

  public withdrawal(data): Observable<any> {
    // return this.base.post('api/v1/order/withdrawal', data);
    return this.base.post('api/v1/user/withdrawal', data);
  }

  public updateProfile(data): Observable<any> {
    return this.base.patch('api/v1/user/me', data);
  }

  public getMarketsList(): Observable<any> {
    return this.base.get('api/v1/settings/markets');
  }

  public getFreeCoinsStatus(userId: number): Observable<any> {
    return this.base.get(`api/v1/user/spc-bonus/${userId}`);
  }

  public postRegistrationBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/reg-bonus/${userId}`);
  }

  public postDepositBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/dep-bonus/${userId}`);
  }

  public postKycBonus(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/kyc-bonus/${userId}`);
  }

  public postUpdateWallets(userId: number): Observable<any> {
    return this.base.post(`api/v1/user/update-wallets/${userId}`);
  }

  public postUpdateWithdrawalStatus(userId: number): Observable<any> {
    return this.base.get(`api/v1/user/check-withdrawal-status/${userId}`);
  }

  public postCheckPendingWalletsStatus(userId: number): Observable<any> {
    return this.base.get(`api/v1/user/check-pending-wallets/${userId}`);
  }

  getUserProfile(): Observable<any> {
    return this.base.get('api/v1/user/me');
  }

  getMasterCardAccountStatus(): Observable<any>  {
    return this.base.get('api/v1/mastercard/account/profile');
  }

  getUserKycStatus(): Observable<any> {
    return this.base.get('api/v1/user/kyc-status');
  }

  getUserLogs(): Observable<any> {
    return this.base.get('api/v1/user/logs');
  }

  getIpWhiteList(): Observable<any> {
    return this.base.get('api/v1/user/white-list');
  }

  addToIpWhiteList(data): Observable<any> {
    return this.base.post('api/v1/user/white-list', data);
  }

  removeFromIpWhiteList(data): Observable<any> {
    return this.base.delete('api/v1/user/white-list', data);
  }

  getWithdrawalWhiteList(): Observable<any> {
    return this.base.get('api/v1/user/withdrawal-white-list');
  }

  addToWithdrawalWhiteList(data): Observable<any> {
    return this.base.post('api/v1/user/withdrawal-white-list', data);
  }

  getLimitAndFees(): Observable<any> {
    return this.base.get('api/v1/settings/fees-and-limits');
}

  removeFromWithdrawalWhiteList(data): Observable<any> {
    return this.base.delete('api/v1/user/withdrawal-white-list', data);
  }

  // trade temporary
  getOrderHistory(showOtherPairs: string): Observable<any> {
    let path = 'api/v1/order/history?market=';
    if (showOtherPairs === 'no') {
      path += this.market;
    }
    return this.base.get(path);
  }

  getOpenOrders(showOtherPairs: string): Observable<any> {
    // let path = 'api/v1/order/open?market=';
    let path = 'api/v1/order/open?market=';
    if (showOtherPairs === 'no') {
      path += this.market;
    }
    return this.base.get(path);
  }

  getOpenStopLimitOrders(userId: number): Observable<any> {
    return of([]);
    // return this.base.get(`api/v1/order/getUserStopLimitOrders/${userId}`);
  }

  exchangeBuy(quantity: number, rate: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
      limit: rate
    };
    return this.base.post('api/v1/order/tradeBuy', data);
  }

  exchangeBuyMarket(quantity: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
    };
    return this.base.post('api/v1/order/tradeBuyMarket', data);
  }

  exchangeStopLimit(order: OrderStopLimitClass): Observable<any> {
    return this.base.post('api/v1/order/create-stop-limit', order);
  }

  exchangeSell(quantity: number, rate: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
      limit: rate
    };
    return this.base.post('api/v1/order/tradeSell', data);
  }

  exchangeSellMarket(quantity: number): Observable<any> {
    const data = {
      marketName: this.market,
      quantity: quantity,
    };
    return this.base.post('api/v1/order/tradeSellMarket', data);
  }

  exchangeSellLadderLimit(takeProfit: number, stopLoss: number, quantity: number): Observable<any> {
    const data = {
      marketName: this.market,
      takeProfit: takeProfit,
      stopLoss: stopLoss,
      quantity: quantity,
    };
    return this.base.post('api/v1/order/tradeSellLadderLimit', data);
  }

  cancelOpenOrder(order): Observable<any> {
    return this.base.delete('api/v1/order/cancel/' + order, {});
  }

  cancelOpenOrders(orderIds: number[]): Observable<any> {
    return this.base.post('api/v1/order/cancel-all', orderIds);
  }

  cancelOpenStopLimitOrder(orderId): Observable<any> {
    return this.base.delete(`api/v1/order/cancelStopLimitOrder/${orderId}`, {});
  }

  deleteStopLimitOrder(orderId, body): Observable<any> {
    return this.base.patch(`api/v1/order/updateStopLimitOrder/${orderId}`, body);
  }

  cancelWithdrawal(withdrawal): Observable<any> {
    // user/cancel-withdrawal/{withdrawalId}
    return this.base.delete(`api/v1/user/cancel-withdrawal/${withdrawal}`, {});
  }

  public getWallet(currency): Observable<any> {
    return this.base.post('api/v1/user/wallet', {currency});
  }

  public initiatePoli(data: FiatClass): Observable<any> {
    return this.base.post('api/v1/user/poli/initiateTransaction', data);
  }

  // public getCurrencyList(): Array<Object> {
  //   return [
  //     {name: 'BTC', fullName: 'Bitcoin'},
  //     {name: 'BCH', fullName: 'Bitcoin Cash'},
  //     {name: 'XRP', fullName: 'Ripple'},
  //     {name: 'ZCL', fullName: 'ZClassic'},
  //     {name: 'ADA', fullName: 'Cardano'},
  //     {name: 'ETH', fullName: 'Ethereum'},
  //     {name: 'ETC', fullName: 'Ethereum Classic'},
  //     {name: 'MFT', fullName: 'Mainframe'},
  //     {name: 'XVG', fullName: 'Verge'},
  //     {name: 'XLM', fullName: 'Stellar'},
  //     {name: 'XMR', fullName: 'Monero'},
  //     {name: 'UP', fullName: 'UpToken'},
  //     {name: 'CMCT', fullName: 'Crowd Machine'},
  //     {name: 'PAY', fullName: 'TenX'},
  //     {name: 'TRX', fullName: 'TRON'},
  //     {name: 'POLY', fullName: 'Polymath'},
  //     {name: 'SC', fullName: 'Siacoin'},
  //     {name: 'LTC', fullName: 'Litecoin'},
  //     {name: 'SWT', fullName: 'Swarm City'},
  //     {name: 'STRAT', fullName: 'Stratis'},
  //     {name: 'RDD', fullName: 'ReddCoin'},
  //     {name: 'REP', fullName: 'Augur'},
  //     {name: 'DASH', fullName: 'Dash'},
  //     {name: 'NLG', fullName: 'Gulden'},
  //     {name: 'RFR', fullName: 'Refereum'},
  //     {name: 'DOGE', fullName: 'Dogecoin'},
  //     {name: 'GBYTE', fullName: 'Byteball Bytes'},
  //     {name: 'ARDR', fullName: 'Ardor'},
  //   ].sort((a, b) => {
  //     if (a.name < b.name ) return -1;
  //     if (a.name > b.name ) return 1;
  //     return 0;
  //   });
  // }

  passSharedCurrenciesList(data: Array<CurrencyInterface>) {
    this.currenciesList.next(data);
  }

  setSharedCurrenciesList(data: Array<CurrencyInterface>) {
    this.currenciesListData = data;
  }

  getSharedCurrenciesList(): Array<CurrencyInterface> {
    return this.currenciesListData;
  }

  getSharedCurrenciesListEmitter(): Observable<Array<CurrencyInterface>> {
    return this.currenciesList.asObservable();
  }

  passMarketPairMinOrderAmount(data: MarketsShortInfoInterface) {
    this.marketPairMinOrderAmount.next(data);
  }

  setMarketPairMinOrderAmount(data: MarketsShortInfoInterface) {
    this.marketPairMinOrderAmountData = data;
  }

  getMarketPairMinOrderAmount(): MarketsShortInfoInterface {
    return this.marketPairMinOrderAmountData;
  }

  getMarketPairMinOrderAmountEmitter(): Observable<MarketsShortInfoInterface> {
    return this.marketPairMinOrderAmount.asObservable();
  }

  passMarketPairNew(data: string) {
    this.marketPairNew.next(data);
  }

  getMarketPairNewEmitter(): Observable<string> {
    return this.marketPairNew.asObservable();
  }

  passExchangeAmount(data: string) {
    this.exchangeAmount.next(data);
  }

  setExchangeAmount(data: string) {
    this.exchangeAmountData = data;
  }

  getExchangeAmount(): string {
    return this.exchangeAmountData;
  }

  getExchangeAmountEmitter(): Observable<string> {
    return this.exchangeAmount.asObservable();
  }

  passExchangePrice(data: string) {
    this.exchangePrice.next(data);
  }

  setExchangePrice(data: string) {
    this.exchangePriceData = data;
  }

  getExchangePrice(): string {
    return this.exchangePriceData;
  }

  getExchangePriceEmitter(): Observable<string> {
    return this.exchangePrice.asObservable();
  }

  passLoginIncomeErrorStatus(data: Array<string>) {
    this.loginIncomeErrorStatus.next(data);
  }

  setLoginIncomeErrorStatus(data: Array<string>) {
    this.loginIncomeErrorStatusData = data;
  }

  getLoginIncomeErrorStatus(): Array<string> {
    return this.loginIncomeErrorStatusData;
  }

  getLoginIncomeErrorStatusEmitter(): Observable<Array<string>> {
    return this.loginIncomeErrorStatus.asObservable();
  }

  passMessageTypeForAlertModal(data: number) {
    this.messageTypeForAlertModal.next(data);
  }

  setMessageTypeForAlertModal(data: number) {
    this.messageTypeForAlertModalData = data;
  }

  getMessageTypeForAlertModal(): number {
    return this.messageTypeForAlertModalData;
  }

  getMessageTypeForAlertModalEmitter(): Observable<number> {
    return this.messageTypeForAlertModal.asObservable();
  }

  passIsLightTheme(data: boolean) {
    this.isLightTheme.next(data);
  }

  setIsLightTheme(data: boolean) {
    this.isLightThemeData = data;
  }

  getIsLightTheme(): boolean {
    return this.isLightThemeData;
  }

  getIsLightThemeEmitter(): Observable<boolean> {
    return this.isLightTheme.asObservable();
  }

  setCurrentTheme(theme) {
    const currentTheme = localStorage.getItem('currentTheme');
    if (currentTheme) {
      this.currentTheme = currentTheme;
    } else {
      this.currentTheme = theme;
    }
  }

  passMessageTypeForGlobalMessage(data: number) {
    this.messageTypeForGlobalMessage.next(data);
  }

  setMessageTypeForGlobalMessage(data: number) {
    this.messageTypeForGlobalMessageData = data;
  }

  getMessageTypeForGlobalMessage(): number {
    return this.messageTypeForGlobalMessageData;
  }

  getMessageTypeGlobalMessageEmitter(): Observable<number> {
    return this.messageTypeForGlobalMessage.asObservable();
  }

  passTotalDepositUSDT(data: number) {
    this.totalDepositUSDT.next(data);
  }

  setTotalDepositUSDT(data: number) {
    this.totalDepositUSDTData = data;
  }

  getTotalDepositUSDT(): number {
    return this.totalDepositUSDTData;
  }

  getTotalDepositUSDTEmitter(): Observable<number> {
    return this.totalDepositUSDT.asObservable();
  }

  passTickHistory(data: Array<TickHistoryModel>) {
    this.ticksHistory.next(data);
  }

  setTickHistory(data: Array<TickHistoryModel>) {
    this.ticksHistoryData = data;
  }

  getTickHistory(): Array<TickHistoryModel> {
    return this.ticksHistoryData;
  }

  getTickHistoryEmitter(): Observable<Array<TickHistoryModel>> {
    return this.ticksHistory.asObservable();
  }

  setCurrencyLastPrice(data: CurrencyLastPriceClass) {
    this.currencyLastPrice = data;
  }

  passCurrencyLastPrice(data: CurrencyLastPriceClass) {
    this.currencyLastPriceData.next(data);
  }

  getСurrencyLastPrice(): CurrencyLastPriceClass {
    return this.currencyLastPrice;
  }

  getСurrencyLastPriceEmitter(): Observable<CurrencyLastPriceClass> {
    return this.currencyLastPriceData.asObservable();
  }

  public convertExpToNumber(_value: number): string {
    const value = '' + _value;
    const newValue = ((value.indexOf('e') >= 0) || (value.indexOf('E') >= 0)) ? this.convertExpToString(_value) : value;

    const newValueArr = newValue.split('.');
    let decimal = newValueArr[1] ? newValueArr[1] : '00000000';

    if (newValueArr && newValueArr[1] && newValueArr[1].length) {
      switch (newValueArr[1].length) {
        case 1:
          decimal = newValueArr[1] + '0000000';
          break;
        case 2:
          decimal = newValueArr[1] + '000000';
          break;
        case 3:
          decimal = newValueArr[1] + '00000';
          break;
        case 4:
          decimal = newValueArr[1] + '0000';
          break;
        case 5:
          decimal = newValueArr[1] + '000';
          break;
        case 6:
          decimal = newValueArr[1] + '00';
          break;
        case 7:
          decimal = newValueArr[1] + '0';
          break;
      }
    } else {
      // console.log(_value);
    }

    return newValueArr[0] + '.' + decimal;
  }

  public removeCountryfromIsoNames(countryIso: string, countries: Array<string>): Array<string> {
    if (countries && countries.length) {
      return countries.filter(country => country !== countryIso);
    } else {
      return countries;
    }
  }

  public convertExpToString(expNumber: number): string {
    const data = String(expNumber).split(/[eE]/);
    if (data.length === 1) {
      return data[0];
    }
    let z = '';
    const sign = expNumber < 0 ? '-' : '';
    const str = data[0].replace('.', '');
    let mag = Number(data[1]) + 1;

    if (mag < 0) {
      z = sign + '0.';
      while (mag++) {
        z += '0';
      }
      return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) {
      z += '0';
    }
    return str + z;
  }

  public getEmailLowercase(data: string): string {
    const login = data.split('@')[0];
    const host = data.split('@')[1];
    return login.toLocaleLowerCase() + '@' + host;
  }

  getSumsubAccessToken(userId, ttlInSecs): Observable<any> {
    return this.base.get(`api/v1/sumsub/access-token?userId=${userId}&ttlInSecs=${ttlInSecs}`);
  }

  getMyRedemptions(): Observable<ReadonlyArray<BCXGRedemption>> {
    return this.base.get(`api/v1/bcxg/my-redemptions`);
  }

  cancelRedemptionRequestByUser(redemptionId: number): Observable<any> {
    return this.base.post(`api/v1/bcxg/cancel-redemption-by-user`, {redemptionId});
  }

  getGoldPricePerOunceUSD(): Observable<number> {
    return this.base.get(`api/v1/bcxg/gold-price-per-ounce-usd`);
  }

  getAccountBCXGValue(): Observable<number> {
    return this.base.get(`api/v1/bcxg/account-bcxg`);
  }

  createRedemption(bcxgRedemption: BCXGRedemption): Observable<any> {
    return this.base.post(`api/v1/bcxg/create-redemption`, bcxgRedemption);
  }

  uploadDocument(formData: FormData) {
    return fromPromise(this.base.postMultipartFormData(`api/v1/user/document-upload`, formData));
  }

  saveDocument(data: any) {
    return this.base.post('api/v1/user/document-send', data);
  }

  checkTwoFa(code: string): Observable<boolean> {
    return this.base.post('check-totp', { code })
      .pipe(map(result => result.is));
  }

  getExchangeRate(type: 'BUY' | 'SELL'): Observable<any> {
    return this.base.get(`exchange-rate-price/${type}`);
  }

  checkTDBonuses(amount: number): Observable<any> {
    return this.base.post(`api/v1/tdbonuses/check`, { amount });
  }

  payForTDBonuses(id: string, amount: string): Observable<any> {
    return this.base.post(`api/v1/tdbonuses/pay`, { id, amount });
  }
}

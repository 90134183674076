import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Subject} from 'rxjs/internal/Subject';
import {environment} from '../../../environments/environment';
import { Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs/internal/Subscription';
import {CryptoCredentialSignUpComponent} from '../../dialogs/crypto-credential-sign-up/crypto-credential-sign-up';
import {MatDialog} from '@angular/material/dialog';
import {WithdrawalComponent} from '../../dialogs/withdrawal/withdrawal.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  protected readonly environment = environment;
  protected readonly localStorage = localStorage;
  private routerSub: Subscription;
  isLandingPage: boolean;
  cryptoTraderCards: any[] = [];
  howItWorksCards: any[] = [];
  isMasterCardAccountActive: boolean;
  isUserStatusFullVerified = false;
  destroySubject$: Subject<void> = new Subject();
  constructor(
    public dataService: DataService, public router: Router, public authService: AuthService, private translateService: TranslateService, private dialog: MatDialog) {
  }

  checkMasterCardProfileAndFullVerifiedStatus() {
    this.dataService.getUserProfile().toPromise();
    this.dataService.getUserProfile().subscribe((userData) => {
      if (userData && userData.first_name && userData.last_name && userData.iin && userData.country_id && userData.birthday) {
        this.dataService.getUserKycStatus().subscribe(data => {
          console.log('USER STATUS FULL VERIFIED', data);
          this.isUserStatusFullVerified = data.status === 1;
        });
      }
    });
    this.dataService.getMasterCardAccountStatus().subscribe((data) => {
      if (data[0]?.status === 'ACTIVE') {
        this.isMasterCardAccountActive = true;
      }
    });
  }

  ngOnInit(): void {
    // this.dialog.open(WithdrawalComponent);
    // this.dialog.open(CryptoCredentialSignUpComponent);
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.classList.add('landing-page');
    this.authService.loginStatus$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.checkMasterCardProfileAndFullVerifiedStatus();
      }
      // else {
      //   alert('Login failed or logged out.');
      // }
    });
     if (localStorage.getItem('jwtToken')) {
        this.checkMasterCardProfileAndFullVerifiedStatus();
     }

    window.localStorage.setItem('darkMode', 'false');
     this.loadItems();
  }

  loadItems(): void {
    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('LANDING_PAGE.BECOME_A_CRYPTO_TRADER_SECTION.ITEMS').subscribe((data: any[]) => {
        this.cryptoTraderCards = data;
      });
    });
    this.translateService.onLangChange.subscribe(() => {

    this.translateService.get('LANDING_PAGE.HOW_IT_WORKS_SECTION.ITEMS').subscribe((data: any[]) => {
      this.howItWorksCards = data;
    });
    });
  }

  registerByMasterCard() {
    if (localStorage.getItem('jwtToken') && this.isUserStatusFullVerified) {
      this.router.navigate(['', { outlets: { popup: ['crypto-credential-sign-up'] } }]);
    } else if (localStorage.getItem('jwtToken') && !this.isUserStatusFullVerified) {
      this.router.navigate(['/user-settings/my-profile']);
    } else if (!localStorage.getItem('jwtToken')) {
      this.router.navigate(['', { outlets: { popup: ['login'] } }]);
    }
  }

  ngOnDestroy(): void {
    const bodyTag = document.getElementsByTagName('body')[0];
    bodyTag.classList.remove('landing-page');
    this.destroySubject$.next();

  }
}

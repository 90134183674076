import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  widget,
  ChartingLibraryWidgetOptions,
  LanguageCode, IChartingLibraryWidget,
} from '../../../../assets/tradingview/charting_library';
import Datafeeds from '../../../../assets/tradingview/datafeeds/datafeed';
import Io from '../../../../assets/tradingview/charting_library/socket.js';
import { DataService } from '../../../services/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-chart-container',
  templateUrl: './chart-container.component.html',
  styleUrls: ['./chart-container.component.scss']
})

export class ChartContainerComponent implements OnInit, OnDestroy {
  private widget: IChartingLibraryWidget;
  private _symbol: ChartingLibraryWidgetOptions['symbol'] = '';
  private _interval: ChartingLibraryWidgetOptions['interval'] = '1D';
  datafeed: any;
  dataCache: any = {};
  getBarTimer: any = null;
  public ls_scalesProperties_textColor: string;
  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] = '../../../../assets/tradingview/charting_library/';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container_id'] = 'tv_chart_container';
  public isLoaded = false;
  public isLightTheme = false;
  public intervalId = null;
  public theme: ChartingLibraryWidgetOptions['theme'] = 'Light';
  private intervals = [];

  private resolutions = {
    1: 'MINUTE_1',
    5: 'MINUTE_5',
    15: 'MINUTE_15',
    30: 'MINUTE_30',
    60: 'HOUR_1',
    120: 'HOUR_2',
    180: 'HOUR_3',
    240: 'HOUR_4',
    '1D': 'DAY_1',
    '3D': 'DAY_3',
    '1W': 'WEEK_1',
    '1M': 'MONTH_1',
  };

  destroySubject$: Subject<void> = new Subject();

  subscriptionItem: any = {};

  constructor(public dataService: DataService) {
    this.loadNewPair();
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.clearChartData();
    for (const int of this.intervals) {
      this.unsubscribeBars(int);
    }
  }

  private loadNewPair() {
    this.clearChartData();
    this.dataService.getMarketPairNewEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
          const pair = this.dataService.getPair();
          this._symbol = pair;
          setTimeout(() => this.loadData(), 400);
          window.history.pushState('trades', '', `/trades/${this.dataService.getPair()}`);
          location.reload();
        });
  }

  private getLanguageFromURL(): LanguageCode | null {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(location.search);

    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
  }

  private getOptions(pair: string) {
    return {
      symbol: pair,
      container_id: this._containerId,
      library_path: this._libraryPath,
      datafeed: this.datafeed,
      custom_css_url: './custom.css',
      interval: '1D',
      locale: this.getLanguageFromURL() || 'en',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as ChartingLibraryWidgetOptions['timezone'],
      debug: false,
      load_last_chart: true,
      auto_save_delay: 5,
      enabled_features: [
        'header_widget',
        'header_saveload',
        'save_shortcut'
      ],
      disabled_features: [
        'use_localstorage_for_settings',
        'save_chart_properties_to_local_storage',
        'header_symbol_search',
      ],

      fullscreen: this._fullscreen,
      autosize: this._autosize,
      loading_screen: {
        foregroundColor: '#FFF',
        backgroundColor: '#FFF'
      },
      toolbar_bg: '#FFF',
      overrides: {
        'paneProperties.background': '#FFF',
        'paneProperties.vertGridProperties.color': '#E8E8E8',
        'paneProperties.horzGridProperties.color': '#E8E8E8',
        'scalesProperties.textColor': '#222222',
      },
      theme: this.theme,
    };
  }

  public loadData() {
    const pair = this.dataService.getPair();
    Io.setSymbol(pair);
    this.datafeed = new Datafeeds(this, this._interval);

    const widgetOptions = this.getOptions(pair);
    this.widget = new widget(widgetOptions);

    this.widget.onChartReady(() => {
      this.dataService.emitCandleUpdates({marketSymbol: pair, candleInterval: this.widget.activeChart().resolution()});

      const savedChart = JSON.parse(localStorage.getItem(pair));

      if (savedChart) {
        this.widget.load(savedChart);
        this.widget.applyOverrides({
          'paneProperties.background': '#FFF',
        });
      }

      this.widget.onShortcut('shift+s', () => {
        this.widget?.save((chart) => {
          localStorage.setItem(pair, JSON.stringify(chart));
        });
      });

      this.widget.activeChart().onIntervalChanged().subscribe(null, (interval) => {
        this.dataService.emitCandleUpdates({marketSymbol: pair, candleInterval: interval});
        this.intervals.push(interval);

        if (this.intervals.length > 1) {
          this.unsubscribeBars(this.intervals.shift());
        }
      });

      setInterval((() => {
        this.intervalId = this.widget?.save((chart) => {
          if (chart) {
          localStorage.setItem(pair, JSON.stringify(chart));
          }
        });
      }).bind(this), 2000);
    });


  }

  public clearChartData() {
    if (this.widget) {
      this.widget.remove();
    }
    this.widget = null;
    Io.clear();
    if (this.getBarTimer) {
      clearTimeout(this.getBarTimer);
    }
    if (this.intervalId) {
      clearTimeout(this.intervalId);
    }
  }

  searchSymbols(userInput, exchange, symbolType, onResultReadyCallback) {
    console.log('mgr search symbols');
    this.dataService.marketsDetails$.pipe(takeUntil(this.destroySubject$)).subscribe(
      (data) => onResultReadyCallback(data.map(
        (market) => ({
          symbol: market.MarketName,
          full_name: market.MarketFullName,
          description: '',
          exchange: market.MarketName.split('-')[0],
          ticker: market.Last,
          type: 'bitcoin',
        })
      ))
    );
  }

  getBars(symbol, resolution, from, to, callback) {
    let data;

    const symbolData = this.dataCache[symbol];
    if (symbolData) {
      data = symbolData[resolution];
    }
    if (resolution === 'D') {
      resolution = '1D';
    }

    if (this.getBarTimer) {
      clearTimeout(this.getBarTimer);
    }

    const fromMs = from * 1000;
    const toMs = to * 1000;

    const fetchCacheData = () => {
      const newBars = [];
      let count = 0;
      data.forEach((element) => {
        const barTime = element.time;
        if (barTime >= fromMs && barTime <= toMs) {
          newBars.push(element);
          count++;
        }
      }, this);

      if (count > 0) {
        newBars.sort((l, r) => l.time > r.time ? 1 : -1);
        callback && callback({ s: 'ok', bars: newBars });
      } else {
        callback && callback({ s: 'no_data' });
      }
      const params = {
        resolution: resolution,
        symbol: symbol,
        type: 'update',
        from: from,
        to: to
      };
      Io.subscribeKline(params, this.onUpdateData.bind(this));
    };

    const requestData = () => {
      const params = {
        resolution: resolution,
        symbol: symbol,
        type: 'kline',
        from: from,
        to: to
      };
      Io.subscribeKline(params, this.onUpdateData.bind(this));

      this.getBarTimer = setTimeout(() => {
        this.getBars(symbol, resolution, from, to, callback);
      }, 400);
    };

    data ? fetchCacheData() : requestData();
  }

  subscribeBars(symbolInfo,
                resolution,
                onRealtimeCallback,
                subscribeUID,
                onResetCacheNeededCallback,
                lastDailyBar) {
    const handler = {
      id: subscribeUID,
      callback: onRealtimeCallback,
    };
    this.subscriptionItem = {
      subscribeUID,
      resolution,
      lastDailyBar,
      handlers: [handler],
    };
    const currentResolution = this.widget.activeChart().resolution();
    const currentResolutionConverted = this.resolutions[currentResolution];

    return this.dataService.SubscribeToCandleUpdates(currentResolutionConverted);
  }

  unsubscribeBars(interval) {
    this.dataService.emitUnsubscribeCandleUpdates({marketSymbol: this.dataService.getPair(), candleInterval: interval});
  }

  getConfig() {
    return {
      supports_search: true,  //  请修改datafeed的searchSymbols函数
      supports_group_request: false, // 设置为true将无法进行单个商品解析
      supports_marks: true,  // 请修改datafeed的getMarks函数
      supports_timescale_marks: true, // 请修改datafeed的getTimescaleMarks函数
      supports_time: true
    };
  }

  getServerTime() {
    return parseInt((+Date.now() / 1000).toFixed(0), 10);
  }

  resolveTVSymbol(symbol) {
    return {
      'name': this._symbol,
      'exchange-traded': '',
      'exchange-listed': '',
      'timezone': 'Asia/Dubai',
      'mainSeriesProperties.style': 3,
      'minmov': 1,
      'minmov2': 0,
      'pointvalue': 1,
      'fractional': false,
      'session': '24x7',
      'has_intraday': true,
      'intraday_multipliers': ['1', '5', '15', '30', '60', '120', '180', '240'],
      'has_no_volume': true,
      'has_fractional_volume': true,
      'description': this._symbol,
      'pricescale': 100000000,
      'ticker': symbol,
      'supported_resolutions': ['1', '5', '15', '30', '60', '120', '180', '240', '1D', '3D', '1W', '1M'],
    };
  }

  onUpdateData(data) {
    console.log('on update data', data);
    if (!data.kline) {
      return false;
    }
    if (!this.dataCache[data.symbol]) {
      this.dataCache[data.symbol] = {};
    }
    if (!this.dataCache[data.symbol][data.resolution]) {
      this.dataCache[data.symbol][data.resolution] = [];
    }
    if (data.type === 'kline' && data.kline.length) {
      data.kline.forEach(elm => {
        this.dataCache[data.symbol][data.resolution].push({...elm, symbol: data.symbol});
      });
    }
    if (data.type === 'update' && data.kline) {

      this.dataCache[data.symbol][data.resolution].push(data.kline);
      this.dataCache = {...this.dataCache};
      console.log('on update data cache', this.dataCache);

      this.subscriptionItem.lastDailyBar = data.kline;
      this.subscriptionItem.handlers?.forEach(handler => handler.callback(data.kline));
    }
  }
}

import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'app-altyn-bank',
  templateUrl: './altyn-bank.component.html',
  styleUrls: ['./altyn-bank.component.scss']
})
export class AltynBankComponent implements OnInit {
  public htmlContent = '';

  constructor(
    private _httpClient: HttpClient,
    private location: Location,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit(): void {
    const navigation = this.location.getState() as { payload: any };

    if (navigation && navigation.payload) {
      const payload = navigation.payload;

      this._httpClient.post('https://test-api.intebix.kz/api/v1/acquiring/altynbank/buy', payload, {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        responseType: 'text' as 'json'
      }).subscribe(
        (response) => {
          this.htmlContent = response as string;
          this.insertHtmlContent(this.htmlContent);
        },
        (error) => {
          console.error('Ошибка:', error);
        }
      );
    } else {
      console.error('Payload не найден');
    }
  }

  private insertHtmlContent(html: string) {
    const tempDiv = this.renderer.createElement('div');
    tempDiv.innerHTML = html;

    const styles = tempDiv.querySelectorAll('link[rel="stylesheet"]');
    styles.forEach(style => {
      const href = style.getAttribute('href');
      const link = this.renderer.createElement('link');
      this.renderer.setAttribute(link, 'rel', 'stylesheet');
      this.renderer.setAttribute(link, 'href', href);
      this.renderer.appendChild(document.head, link);
    });

    const scripts = tempDiv.querySelectorAll('script');
    scripts.forEach(script => {
      const src = script.getAttribute('src');
      const newScript = this.renderer.createElement('script');
      if (src) {
        this.renderer.setAttribute(newScript, 'src', src);
      } else {
        newScript.text = script.innerHTML;
      }
      this.renderer.appendChild(document.body, newScript);
    });

    const contentDiv = this.el.nativeElement.querySelector('#content');
    if (contentDiv) {
      contentDiv.innerHTML = tempDiv.innerHTML;
    }
  }
}
